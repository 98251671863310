import React from "react";
import { Button, Typography } from '@material-ui/core';
import { withRouter } from "react-router";

const BackButtonInner = ({ history: { goBack }, ...props }) => (
  <Button variant="contained" color="secondary" onClick={goBack} size="small" >
    <Typography style={{fontSize: '14px'}}>&lt; Atrás</Typography>
  </Button>
);

export const BackButton = withRouter(BackButtonInner);

import React, { useEffect, useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ReferenceInput,
  SelectInput,
  EditButton,
  required,
  Datagrid,
  TextField,
  DateField,
  useRecordContext,
  ReferenceManyField,
  FileInput,
  FileField,
  Button,
  TopToolbar,
  DeleteButton,
  DateInput,
  useGetOne,
  Loading,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  UrlField,
  FunctionField,
} from "react-admin";
import { useFormState } from "react-final-form";
import { Typography, Grid } from "@material-ui/core";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { get } from "lodash";
import { BackButton } from "../../components/BackButton";
import { casoPuedeCrearDocumentos } from "../../helpers/helpers";
import MUIButton from "@material-ui/core/Button";

const CreateRelatedDocumentoButton = ({ record }) => (
  <Button
    component={RouterLink}
    to={{
      pathname: "/documentos/create",
      state: { record: { caso_id: record.id } },
    }}
    color="primary"
    variant="contained"
    size="small"
    disabled={!casoPuedeCrearDocumentos(record)}
  >
    <Typography variant="caption" style={{ fontSize: "12px" }}>
      + Añadir documento
    </Typography>
  </Button>
);

export const ListadoDocumentosPorCaso = (props) => {
  const record = useRecordContext();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">Documentos asociados al revolving</Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <CreateRelatedDocumentoButton record={record} />
      </Grid>
      <Grid item xs={12}>
        <ReferenceManyField
          reference="documentos"
          target="caso_id"
          label="Documentos"
          record={record}
          perPage={100}
        >
          <Datagrid
            empty={<p>No hay documentos para este revolving.</p>}
            resource="documentos"
            rowClick="expand"
            isRowExpandable={() => false}
          >
            <DateField source="created_at" label="Entrada" />
            <TextField source="tipodocumento.nombre" label="Origen" />
            {/* <TextField source="tipo_mime" label="Mime" /> */}
            <DateField source="fecha_efecto" label="F. Efecto" />
            <FunctionField
              source="download_url"
              label=""
              render={(record) => (
                <MUIButton
                  color="primary"
                  href={record.download_url}
                  target="_blank"
                >
                  Descargar
                </MUIButton>
              )}
            />
            <FunctionField
              source="sharepoint_url"
              label=""
              render={(record) => (
                <MUIButton
                  color="primary"
                  href={record.sharepoint_url}
                  target="_blank"
                >
                  Abrir en Sharepoint
                </MUIButton>
              )}
            />
            <EditButton />
            <DeleteButton mutationMode="pessimistic" redirect={false} />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          Documentos asociados al precontacto
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        &nbsp;
      </Grid>
      <Grid item xs={12}>
        <ReferenceManyField
          reference="documentos"
          target="precontacto_id"
          label="Documentos"
          record={record.precontacto}
        >
          <Datagrid
            empty={<p>No hay documentos para este precontacto.</p>}
            resource="documentos"
            rowClick="edit"
          >
            <DateField source="created_at" label="Entrada" />
            <TextField source="tipodocumento.nombre" label="Origen" />
            {/* <TextField source="tipo_mime" label="Mime" /> */}
            <DateField source="fecha_efecto" label="F. Efecto" />
            <FunctionField
              source="download_url"
              label=""
              render={(record) => (
                <MUIButton
                  color="primary"
                  href={record.download_url}
                  target="_blank"
                >
                  Descargar
                </MUIButton>
              )}
            />
            <FunctionField
              source="sharepoint_url"
              label=""
              render={(record) => (
                <MUIButton
                  color="primary"
                  href={record.sharepoint_url}
                  target="_blank"
                >
                  Abrir en Sharepoint
                </MUIButton>
              )}
            />
            <EditButton />
            <DeleteButton mutationMode="pessimistic" redirect={false} />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
    </Grid>
  );
};

const CrearDocumentoPorCasoActions = (props) => {
  return (
    <TopToolbar>
      <BackButton />
    </TopToolbar>
  );
};

const FechaEfectoInner = ({ tipodocumento_id, ...props }) => {
  const { data, loading, error } = useGetOne(
    "tipodocumentos",
    tipodocumento_id
  );
  if (loading) return <Loading />;
  if (error) return <p>ERROR</p>;
  return (
    <DateInput
      label="Fecha efecto"
      source="fecha_efecto"
      disabled={data.requiere_fecha_efecto ? false : true}
      required={data.requiere_fecha_efecto ? false : true}
    />
  );
};

const FechaEfecto = (props) => {
  const { values } = useFormState();

  if (values && values.tipodocumento_id) {
    return (
      <FechaEfectoInner {...props} tipodocumento_id={values.tipodocumento_id} />
    );
  }
  return null;
};

const CrearDocumentoPorCasoInner = ({
  history: { goBack },
  location,
  staticContext,
  ...props
}) => {
  const onSuccess = () => goBack();
  const caso_id = get(location, "state.record.caso_id");
  if (!caso_id) {
    return <p>Solo se pueden adjuntar documentos a un caso!</p>;
  }
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      actions={<CrearDocumentoPorCasoActions />}
    >
      <SimpleForm>
        <TextInput
          source="caso_id"
          initialvalue={caso_id}
          disabled={true}
          style={{ display: "none" }}
        />
        <ReferenceInput
          label="Tipo documento"
          source="tipodocumento_id"
          reference="tipodocumentos"
          sort={{ field: "orden", order: "ASC" }}
          validate={[required()]}
          perPage={100}
        >
          <SelectInput optionText="nombre" />
        </ReferenceInput>
        <FechaEfecto />
        <FileInput
          source="documento_file"
          multiple={false}
          labelSingle="Deje aquí el fichero"
        >
          <FileField source="src" title="Nombre" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export const CrearDocumentoPorCaso = withRouter(CrearDocumentoPorCasoInner);

const EditarDocumentoPorCasoInner = ({
  history: { goBack },
  location,
  staticContext,
  ...props
}) => {
  const onSuccess = () => goBack();
  return (
    <Edit {...props} onSuccess={onSuccess} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput
          source="caso_id"
          disabled={true}
          style={{ display: "none" }}
        />
        <ReferenceInput
          label="Tipo documento"
          source="tipodocumento_id"
          reference="tipodocumentos"
          validate={[required()]}
          disabled={false}
          perPage={100}
        >
          <SelectInput optionText="nombre" />
        </ReferenceInput>
        <FechaEfecto />

        {/* <FileInput
          source="documento_file"
          multiple={false}
          labelSingle="Deje aquí el fichero"
        >
          <FileField source="src" title="Nombre" />
        </FileInput> */}

        <FunctionField
          source="download_url"
          label=""
          render={(record) => (
            <div>
              <p>
                <strong>Documento</strong>
              </p>
              <p>{record.sharepoint_url}</p>
              <MUIButton
                color="primary"
                href={record.download_url}
                target="_blank"
              >
                Descargar
              </MUIButton>
            </div>
          )}
        />
      </SimpleForm>
    </Edit>
  );
};

export const EditarDocumentoPorCaso = withRouter(EditarDocumentoPorCasoInner);
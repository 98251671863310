import { includes, capitalize, find, replace, get, sortBy } from "lodash";

export class EstadoCaso {
  static Inicio = new EstadoCaso("inicio", null, 1);
  static NoContactado = new EstadoCaso("no_contactado", null, 2);
  static no_interesado = new EstadoCaso("no_interesado", null, 3);
  static sin_prespuesta = new EstadoCaso("sin_respuesta", null, 4);
  static Defectuoso = new EstadoCaso("defectuoso", null, 5);
  static NoViable = new EstadoCaso("no_viable", null, 6);
  static SolicitandoDocumentacion = new EstadoCaso(
    "solicitando_documentacion",
    null,
    7
  );
  static aceptacion_previa = new EstadoCaso("aceptacion_previa", null, 8);
  static PreparandoPack = new EstadoCaso("preparando_pack", null, 9);
  static EsperandoPackFirmado = new EstadoCaso(
    "esperando_pack_firmado",
    null,
    10
  );
  static PreparandoReclamacionExtrajudicial = new EstadoCaso(
    "preparando_reclamacion_extrajudicial",
    "Preparando REX 1",
    11
  );
  static ReclamacionExtrajudicialPresentada = new EstadoCaso(
    "reclamacion_extrajudicial_presentada",
    "REX 1 presentada",
    12
  );
  static preparando_reclamacion_extrajudicial_2 = new EstadoCaso(
    "preparando_reclamacion_extrajudicial_2",
    "Preparando REX 2",
    13
  );
  static reclamacion_extrajudicial_presentada_2 = new EstadoCaso(
    "reclamacion_extrajudicial_presentada_2",
    "REX 2 presentada",
    14
  );
  static preparando_reclamacion_extrajudicial_3 = new EstadoCaso(
    "preparando_reclamacion_extrajudicial_3",
    "Preparando REX 3",
    15
  );
  static ActuacionesSAC = new EstadoCaso("actuaciones_sac", null, 16);

  static PendienteEstudioJuridico = new EstadoCaso(
    "pdte_estudio_juridico",
    "PDTE. estudio jurídico revolving",
    17
  );

  static juridico_demanda_presentada_normal = new EstadoCaso(
    "juridico_demanda_presentada_normal",
    null,
    18
  );

  static juridico_demanda_presentada_normal_asufin = new EstadoCaso(
    "juridico_demanda_presentada_asufin",
    null,
    19
  );

  static pdte_estudio_juridico_documentacion = new EstadoCaso(
    "pdte_estudio_juridico_documentacion",
    "Revisión tras exceso de intentos SAC",
    20
  );

  static jdpd = new EstadoCaso(
    "juridico_demanda_presentada_documentacion",
    "Descartado tras exceso de intentos SAC",
    21
  );

  static propuesta_acuerdo = new EstadoCaso("propuesta_acuerdo", null, 22);

  static facturacion = new EstadoCaso("facturacion", null, 23);
  static cobrado = new EstadoCaso("cobrado", null, 24);
  static procedimiento_judicial_cobro = new EstadoCaso(
    "procedimiento_judicial_cobro",
    null,
    25
  );

  static demanda_hecha_pdte_vencimiento = new EstadoCaso(
    "demanda_hecha_pdte_vencimiento",
    null,
    26
  );

  constructor(name, title, idx) {
    this.name = name;
    this.title = title;
    this.idx = idx;
  }

  toString() {
    return this.title
      ? this.title
      : capitalize(replace(this.name, new RegExp("_", "g"), " "));
  }

  static getEstadosCaso() {
    return sortBy(Object.values(EstadoCaso), function (o) {
      return o.idx ? o.idx : o.name;
    });
  }

  static fromString(name) {
    // console.log(EstadoCaso.getEstadosCaso().map(c => c.name));
    return find(EstadoCaso.getEstadosCaso(), (e) => e.name === name);
  }
}

export class SubestadoCaso {
  static ssa = new SubestadoCaso("NULL", "Sin subestado asignado", 0);
  static pfd = new SubestadoCaso("Pendiente firma digital", null, 1);
  static pfm = new SubestadoCaso("Pendiente firma manual", null, 2);
  static fdc = new SubestadoCaso("Firma digital completada", null, 3);
  static fdr = new SubestadoCaso("Firma digital rechazada", null, 4);
  static fdcancel = new SubestadoCaso("Firma digital cancelada", null, 5);
  static fde = new SubestadoCaso("Firma digital expirada", null, 6);
  static asac1 = new SubestadoCaso("Actuaciones SAC 1", null, 7);
  static asac2 = new SubestadoCaso("Actuaciones SAC 2", null, 8);
  static asac3 = new SubestadoCaso("Actuaciones SAC 3", null, 9);
  static asac4 = new SubestadoCaso("Actuaciones SAC 4", null, 10);
  static asac5 = new SubestadoCaso("Actuaciones SAC 5", null, 11);
  static asac6 = new SubestadoCaso("Actuaciones SAC 6", null, 12);
  static asaca = new SubestadoCaso("Actuaciones SAC adicionales", null, 13);
  static gp = new SubestadoCaso("Gestión previa", null, 14);
  static a = new SubestadoCaso("Asignada", null, 15);
  static pendiente_analisis = new SubestadoCaso("Pdte. Análisis", null, 16);
  static epc = new SubestadoCaso("Envío propuesta a cliente", null, 17);
  static na = new SubestadoCaso("No Acepta", null, 18);
  static a = new SubestadoCaso("Acepta", null, 19);
  static deplf = new SubestadoCaso(
    "Datos enviados para la facturación",
    null,
    20
  );
  static efr = new SubestadoCaso("Enviada factura y registro", null, 21);
  static phe = new SubestadoCaso("Pdte. Hoja de encargo", null, 22);
  static pda = new SubestadoCaso("Pdte. Docs Asufin", null, 23);
  static p = new SubestadoCaso("Presentada", null, 24);
  static rc = new SubestadoCaso("Rehacer reclamación", null, 25);
  static dr = new SubestadoCaso("Dudas responsable", null, 26);

  // static nrr = new SubestadoCaso("No respuesta reclamación");
  // static rsd = new SubestadoCaso("Respuesta sin docs");
  // static sedac = new SubestadoCaso("SAC envía docs. a cliente");
  // static og = new SubestadoCaso("Otras gestiones");
  // static rcr = new SubestadoCaso("Revisión con responsable");

  constructor(name, title, idx) {
    this.name = name;
    this.title = title;
    this.idx = idx;
  }

  toString() {
    return this.title ? this.title : this.name;
  }

  static getSubestadosCaso() {
    return sortBy(Object.values(SubestadoCaso), function (o) {
      return o.idx ? o.idx : o.name;
    });
  }

  static fromString(name) {
    return find(SubestadoCaso.getSubestadosCaso(), (e) => e.name === name);
  }
}

export const casoPuedeCrearDocumentos = (caso) =>
  !includes(
    [
      EstadoCaso.Inicio,
      EstadoCaso.NoContactado,
      EstadoCaso.Defectuoso,
      EstadoCaso.NoViable,
      EstadoCaso.SolicitandoDocumentacion,
    ].map((e) => e.name),
    caso?.estado
  );

export const casoPuedeEditarExpediente = (caso) =>
  includes(
    [
      EstadoCaso.Inicio,
      EstadoCaso.NoContactado,
      EstadoCaso.SolicitandoDocumentacion,
    ].map((e) => e.name),
    caso?.estado
  );

export const esRole = (identity, roleName) =>
  includes(get(identity, "roleNames", []), roleName);

import React from "react";
import { Typography } from "@material-ui/core";
import { TextField } from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useComentarioStyles = makeStyles({
  clamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
});

const LogActivityLogFirmaDigital = ({ record: registro }) => {
  const classes = useComentarioStyles();
  return registro ? (
    <>
      <Typography
        variant="body2"
        className={classes.clamp}
      >
        {new Date(registro.created_at).toLocaleString()} {registro.texto}
      </Typography>
    </>
  ) : null;
};
export default LogActivityLogFirmaDigital;

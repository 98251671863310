import { Login, LoginForm } from 'react-admin';
import Logo from '../assets/Logo_gestify_sobrenegro.png';

const GestifyLoginPage = () => (
    <Login
        // A random image that changes everyday
        backgroundImage="https://source.unsplash.com/random/1600x900/daily"
    >
        <div style={{textAlign: 'center', paddingBottom: '10px'}}>
        <img src={Logo} style={{maxWidth: '60%'}}/>
        </div>
        <LoginForm fullWidth />
    </Login>
);

export default GestifyLoginPage;
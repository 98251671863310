import React, { useEffect, useMemo, useState } from "react";
import {
  TextField as MUITextField,
  FormControl,
  CircularProgress,
  MenuItem,
  FormHelperText,
  InputLabel,
  Select,
} from "@material-ui/core";
import { useGetList } from "react-admin";

const SubAccionComentario = ({
  accion,
  valor,
  setValor,
  setDisableOkButton,
  classes,
}) => {
  useEffect(() => {
    if (valor?.texto) {
      setDisableOkButton(false);
    } else {
      setDisableOkButton(true);
    }
  }, [valor.texto]);
  return (
    <div>
      <MUITextField
        className={classes.formControl}
        minRows={10}
        multiline
        fullWidth
        type="text"
        label="Comentario"
        variant="outlined"
        helperText={`Comentario aclaratorio`}
        value={valor.texto}
        onChange={(event) => setValor({ ...valor, texto: event.target.value })}
        required
      />
    </div>
  );
};

const SubAccionComentarioAndResponsable = ({
  accion,
  valor,
  setValor,
  setDisableOkButton,
  classes,
  roles_responsable,
}) => {
  const { loading, data, ids, loaded } = useGetList(
    "usuarios",
    { page: 1, perPage: 1000 },
    {},
    {}
  );

  useEffect(() => {
    if (valor?.texto && valor?.responsable_id) {
      setDisableOkButton(false);
    } else {
      setDisableOkButton(true);
    }
  }, [valor.texto, valor.responsable_id]);

  if (loading) return <CircularProgress />;
  return (
    <div>
      <MUITextField
        className={classes.formControl}
        minRows={10}
        multiline
        fullWidth
        type="text"
        label="Comentario"
        variant="outlined"
        helperText={`Comentario aclaratorio`}
        value={valor.texto}
        onChange={(event) => setValor({ ...valor, texto: event.target.value })}
        required
      />
      <FormControl className={classes.formControl}>
        <InputLabel id="responsable-helper-label">Resonsable</InputLabel>
        <Select
          labelId="responsable-helper-label"
          id="responsable-helper"
          value={valor.responsable_id || "0"}
          onChange={(event) =>
            setValor({ ...valor, responsable_id: event.target.value })
          }
        >
          <MenuItem value={0}>
            <em>Sin responsable</em>
          </MenuItem>
          {!ids && <CircularProgress />}
          {ids &&
            ids
              .filter((id) => {
                return data[id].role_names.some(
                  (r) => roles_responsable.indexOf(r) !== -1
                );
              })
              .map((id) => (
                <MenuItem key={id} value={id}>
                  {data[id].name}
                </MenuItem>
              ))}
        </Select>
        <FormHelperText>Responsable asignado</FormHelperText>
      </FormControl>
    </div>
  );
};

const SubAccionAssignacionAbogado = ({
  accion,
  valor,
  setValor,
  setDisableOkButton,
  classes,
  roles_responsable,
}) => {
  const { loading, data, ids, loaded } = useGetList(
    "usuarios",
    { page: 1, perPage: 1000 },
    {},
    {}
  );

  useEffect(() => {
    if (valor?.responsable_id) {
      setDisableOkButton(false);
    } else {
      setDisableOkButton(true);
    }
  }, [valor.responsable_id]);

  if (loading) return <CircularProgress />;
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="responsable-helper-label">Resonsable</InputLabel>
        <Select
          labelId="responsable-helper-label"
          id="responsable-helper"
          value={valor.responsable_id || "0"}
          onChange={(event) =>
            setValor({ ...valor, responsable_id: event.target.value })
          }
        >
          <MenuItem value={0}>
            <em>Sin responsable</em>
          </MenuItem>
          {!ids && <CircularProgress />}
          {ids &&
            ids
              .filter((id) => {
                return data[id].role_names.some(
                  (r) => roles_responsable.indexOf(r) !== -1
                );
              })
              .map((id) => (
                <MenuItem key={id} value={id}>
                  {data[id].name}
                </MenuItem>
              ))}
        </Select>
        <FormHelperText>Responsable asignado</FormHelperText>
      </FormControl>
    </div>
  );
};

const SubAccionEstablecerFecha = ({
  accion,
  valor,
  setValor,
  setDisableOkButton,
  classes,
  texto,
  campo,
}) => {
  useEffect(() => {
    if (valor?.fecha) {
      setDisableOkButton(false);
    } else {
      setDisableOkButton(true);
    }
  }, [valor.fecha]);

  const fechaActual = valor?.fecha || new Date();

  const dateToPicker = (fecha) => {
    // console.log("fecha", fecha);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const days = fecha.getDate();

    // const pattern = `yyyy-MM-ddThh:mm`;

    const picker = `${year}-${`${month}`.padStart(2, "0")}-${`${days}`.padStart(
      2,
      "0"
    )}`;
    // console.log("picker", picker);
    return picker;
  };

  return (
    <div>
      <MUITextField
        type="date"
        label=""
        variant="outlined"
        helperText={texto}
        value={dateToPicker(fechaActual)}
        onChange={(event) => {
          setValor({ ...valor, fecha: new Date(event.target.value) });
        }}
      />
    </div>
  );
};

const SubaccionEnableOkButton = ({setDisableOkButton}) => {
  useEffect(() => {
    setDisableOkButton(false);
  });
  return null;
};

const Subacciones = ({ subaccion, ...otherprops }) => {
  switch (subaccion.accion) {
    case "Comentario":
      return <SubAccionComentario {...subaccion} {...otherprops} />;
    case "ComentarioAndResponsable":
      return (
        <SubAccionComentarioAndResponsable {...subaccion} {...otherprops} />
      );
    case "AsignacionAbogado":
      return (
        <SubAccionAssignacionAbogado
          {...subaccion}
          {...otherprops}
          roles_responsable={["abogado", "abogado_responsable"]}
        />
      );
    case "establecer_fecha":
      return <SubAccionEstablecerFecha {...subaccion} {...otherprops} />;
    default:
      // Si no hay subacciones en un cambio de estado activamos el ok
      // simplemente pq ha seleccionado una opcion
      return <SubaccionEnableOkButton {...subaccion} {...otherprops} />
  }
};

export default Subacciones;

import React from "react";
import { Typography } from "@material-ui/core";
import { TextField } from "react-admin";

export const TextFieldWithEmptyText = (props) => {
    const { emptyMessage, ...rest } = props;
    if (props?.record[props?.source]) {
        return <TextField {...rest} />;
    }
    return (
        <Typography style={{ textDecoration: "none" }} variant="body2">
            {emptyMessage || '-'}
        </Typography>
    );
};

import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Link,
  Button,
} from "@material-ui/core";
import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ReferenceInput,
  SelectInput,
  required,
  List,
  Datagrid,
  DateField,
  Show,
  SimpleShowLayout,
  FormWithRedirect,
  Labeled,
  BooleanField,
  BooleanInput,
  Toolbar,
  useRedirect,
  TopToolbar,
  SelectField,
} from "react-admin";
import { withRouter, useLocation } from "react-router";
import { BackButton } from "../../components/BackButton";

export const ComentarioDetailsEdit = ({ casoId, record }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6" gutterBottom>
        Comentario
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          {casoId && (
            <TextInput
              source="caso_id"
              style={{ display: "none" }}
              disabled
              initialValue={casoId}
            />
          )}
          <TextInput
            source="tipo_comentario"
            style={{ display: "none"}}
            disabled
            initialValue="comentario" />
          <Labeled
            label="Medio de contacto"
            source="mediodecontacto_id"
            resource="comentarios"
            fullWidth
          >
            <ReferenceInput
              label=""
              source="mediodecontacto_id"
              reference="mediosdecontacto"
              validate={[required()]}
              record={record}
            >
              <SelectInput optionText="nombre" />
            </ReferenceInput>
          </Labeled>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6}>
          <Labeled
            label="Tipo"
            source="tipo_comentario"
            resource="comentarios"
            fullWidth
          >
            <SelectInput
              options={{ label: "" }}
              record={record}
              source="tipo_comentario"
              choices={[
                { id: "Comercial", name: "Comercial" },
                { id: "Juridico", name: "Jurídico" },
              ]}
            />
          </Labeled>
        </Grid> */}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Labeled
            label="Texto del comentario"
            source="texto"
            resource="comentarios"
            fullWidth
          >
            <TextInput
              source="texto"
              resource="comentarios"
              record={record}
              options={{ label: "", minRows: 5 }}
              multiline
            />
          </Labeled>
        </Grid>
      </Grid>
    </Box>
  );
};

const ComentarioActions = (props) => {
  return (
    <TopToolbar>
      <BackButton />
    </TopToolbar>
  );
};

const ComentarioEditInner = ({ history: { goBack }, staticContext, ...props }) => {
  const onSuccess = () => goBack();

  return (
    <Edit
      {...props}
      title="Editar Comentario"
      onSuccess={onSuccess}
      actions={<ComentarioActions />}
    >
      <SimpleForm warnWhenUnsavedChanges>
        <ComentarioDetailsEdit />
      </SimpleForm>
    </Edit>
  );
};

export const ComentarioEdit = withRouter(ComentarioEditInner);

const ComentarioCreateInner = ({
  history: { goBack, push },
  location: { state },
  staticContext,
  ...props
}) => {
  const onSuccess = () => {
    if (state && state.record && state.record.caso_id) {
      // Usamos esta URL para el retorno del back
      push(`/casos/${state.record.caso_id}/show`);
    } else {
      goBack();
    }
  };

  return (
    <Create
      {...props}
      title="Crear Comentario"
      
      onSuccess={onSuccess}
      actions={<ComentarioActions />}
    >
      <SimpleForm>
        <ComentarioDetailsEdit casoId={state?.record?.caso_id} />
      </SimpleForm>
    </Create>
  );
};

export const ComentarioCreate = withRouter(ComentarioCreateInner);

import React from "react";
import ReviewIcon from "@material-ui/icons/Comment";
import TransformIcon from "@material-ui/icons/Transform";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

const LogActivityIcon = ({ registro }) => {
  switch (registro.tipo_comentario) {
    case "comentario":
      return (
        <ReviewIcon
          fontSize="small"
          color="primary"
          style={{ paddingLeft: 3 }}
        />
      );
    case "cambio_estado":
      return (
        <TransformIcon
          fontSize="small"
          color="primary"
          style={{ paddingLeft: 3 }}
        />
      );
    case "contacto_externo":
      return (
        <RecordVoiceOverIcon
          fontSize="small"
          color="primary"
          style={{ paddingLeft: 3 }}
        />
      );
    case "cambio_subestado":
      return (
        <TransformIcon
          fontSize="small"
          color="secondary"
          style={{ paddingLeft: 3 }}
        />
      );
      break;
  }

  return null;
};

export default LogActivityIcon;

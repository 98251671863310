import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  PasswordInput,
  List,
  Datagrid,
  TextField,
  EmailField,
  FunctionField,
  ReferenceArrayInput,
  SelectArrayInput,
  DeleteButton,
} from "react-admin";
import Chip from "@material-ui/core/Chip";

export const UsuariosCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        required
        helperText="Nombre del usuario"
        fullWidth
      />
      <TextInput
        source="email"
        required
        helperText="Correo Electrónico del usuario"
      />
      <PasswordInput
        source="password"
        required
        helperText="Contraseña del usuario"
      />
      <PasswordInput
        source="password_confirmation"
        required
        helperText="Repita contraseña del usuario"
      />
      <ReferenceArrayInput source="roles_ids" reference="roles">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export const UsuariosEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput
        source="email"
        disabled
        helperText="Correo Electrónico del usuario"
      />
      <TextInput source="name" required helperText="Nombre del usuario" />
      <ReferenceArrayInput source="roles_ids" reference="roles">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const UsuarioList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      {/* <TextField source="id" /> */}
      <TextField source="name" />
      <EmailField source="email" />
      <FunctionField
        source="role_names"
        render={(record) =>
          record?.role_names
            ? record.role_names.map((r) => <Chip key={r} label={r} />)
            : null
        }
      />
      <DeleteButton />
    </Datagrid>
  </List>
);

import React from "react";
import { Create, SimpleForm, TextInput, Edit, EditButton, required } from "react-admin";

export const OrigenleadCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="nombre" />
    </SimpleForm>
  </Create>
);

export const OrigenleadEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="nombre" validate={required()} />
    </SimpleForm>
  </Edit>
);

import React, { useEffect, useState } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  LoadingPage,
  Title,
  useDataProvider,
  useGetIdentity,
  useGetList,
  useRedirect,
} from "react-admin";
import Board from "react-trello";
import {
  capitalize,
  chain,
  countBy,
  get,
  groupBy,
  includes,
  map,
  replace,
  sortBy,
} from "lodash";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

export default () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [error, setError] = useState(false);
  const [expedientesLoading, setExpedientesLoading] = useState(false);
  const [expedientes, setExpedientes] = useState([]);

  const [allOrMe, setAllOrMe] = useState("me");
  const { identity, loading: identityLoading } = useGetIdentity();

  const handleAllOrMe = (event, newValue) => {
    setAllOrMe(newValue);
  };

  const getExpedientes = async (id_filtro, campo_filtro) => {
    try {
      setError(false);
      setExpedientesLoading(true);
      let filtro = {};
      if (id_filtro && filtro) {
        filtro = { [campo_filtro]: id_filtro };
      }
      const response = await dataProvider.getList("casos", {
        pagination: { page: 1, perPage: 400 },
        sort: { field: "fecha_entrada", order: "ASC" },
        filter: { ...filtro, timeout_lte: (~~(Date.now() / 1000)) + 24 * 3600, },
      });
      setExpedientes(response.data);
    } catch (err) {
      console.log("Exception ", err);
      setError(true);
    } finally {
      setExpedientesLoading(false);
    }
  };

  useEffect(() => {
    if (!identity || identityLoading) return;
    // console.log("useEffect", identity, identityLoading, allOrMe);

    const roles = get(identity, "roleNames", []);
    const esComercial = includes(roles, "comercial");
    const esAbogado =
      includes(roles, "abogado") || includes(roles, "abogado_responsable");

    const filtro =
      allOrMe === "all"
        ? null
        : esComercial
        ? "comercial_asignado_id[0]"
        : esAbogado
        ? "abogado_asignado_id[0]"
        : null;

    // console.log("getExp", roles, esComercial, esAbogado, filtro);

    getExpedientes(filtro ? identity.id : null, filtro);
  }, [identity, identityLoading, allOrMe]);

  const onClick = (cardId, metadata, laneId) => {
    redirect("show", "/casos", cardId);
  };

  const lanes = {
    lanes: chain(expedientes)
      .groupBy((e) => e.estado)
      .mapValues((el) =>
        sortBy(el, (e) =>
          e.timeout ? new Date(e.timeout * 1000) : new Date(e.fecha_entrada)
        )
      )
      .mapValues((el) =>
        map(el, (e) => ({
          id: `${e.id}`,
          title: `Rev-${e.id}\n${e.expediente || ""}`,
          description: `${
            e.precontacto.nombre_completo || e.precontacto.nombre_alias
          }\n${e.precontacto.email || "-"}`,
          label: e.expediente || "",
          // label: e.timeout ? new Date(e.timeout).toLocaleString() : undefined,
          draggable: false,
          tags: [
            { title: e.origenlead?.nombre, bgcolor: "#0079BF", color: "white" },
            {
              title: e.timeout
                ? new Date(e.timeout * 1000).toLocaleString()
                : "n/a",
              bgcolor: e.timeout && e.timeout < Date.now() ? undefined : "gray",
              color: "white",
            },
          ],
        }))
      )
      .transform((result, value, key) => {
        result.push({
          id: key,
          title: capitalize(replace(key, new RegExp("_", "g"), " ")),
          label: `${value.length}`,
          cards: value,
        });
      }, [])
      .value(),
  };

  // console.log("lanes", lanes);
  if (identityLoading || expedientesLoading) return <LoadingPage />;
  if (error) return <p>Error</p>;

  return (
    <>
      <ToggleButtonGroup
        value={allOrMe}
        exclusive
        onChange={handleAllOrMe}
        aria-label="Filtrar revolving"
      >
        <ToggleButton value="all" aria-label="Todos">
          Todos
        </ToggleButton>
        <ToggleButton value="me" aria-label="Mios">
          Solo mios
        </ToggleButton>
      </ToggleButtonGroup>

      <Board
        style={{ backgroundColor: "transparent" }}
        data={lanes}
        laneDraggable={false}
        cardDraggable={false}
        hideCardDeleteIcon={true}
        onCardClick={onClick}
      />
    </>
  );
};

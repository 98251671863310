import axios from "axios";

export default (apiUrl) => {
  const axiosClient = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
  });
  return {
    // authentication
    // login: ({ username, password }) =>
    //   axiosClient
    //     .post(`${apiUrl}/login`, {
    //       username: username,
    //       password: password,
    //     })
    //     .then(
    //       (response) => {
    //         console.log(response);
    //         if (response.status < 200 || response.status >= 300) {
    //           return Promise.reject({ message: "Credenciales inválidas." });
    //         }
    //         if (!response.data || !response.data.token) {
    //           return Promise.reject({ message: "Error en el servidor." });
    //         }
    //         // Authentication is good
    //         sessionStorage.setItem("auth", response.data.token);
    //         return Promise.resolve();
    //       },
    //       (error) => {
    //         return Promise.reject({
    //           message: "Las credenciales no son válidas!",
    //         });
    //       }
    //     ),
    login: ({ username, password }) =>
      axiosClient.get(`${apiUrl}/sanctum/csrf-cookie`).then(() =>
        axiosClient
          .post(`${apiUrl}/login`, {
            username: username,
            password: password,
          })
          .then(
            (response) => {
              console.log(response);
              if (response.status < 200 || response.status >= 300) {
                return Promise.reject({ message: "Credenciales inválidas." });
              }
              if (!response.data || !response.data.token) {
                return Promise.reject({ message: "Error en el servidor." });
              }
              // Authentication is good
              sessionStorage.setItem("auth", response.data.token);
              sessionStorage.setItem("permissions", JSON.stringify( response.data.permissions) );
              sessionStorage.setItem("profile", JSON.stringify( response.data.profile ) );
              return Promise.resolve();
            },
            (error) => {
              return Promise.reject({
                message: "Las credenciales no son válidas!",
              });
            }
          )
      ),
    checkError: (error) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("permissions");
        sessionStorage.removeItem("profile");
        return Promise.reject({ message: "No autorizado" });
      }
      // other error code (404, 500, etc): no need to log out
      return Promise.resolve();
    },
    checkAuth: (params) =>
      typeof sessionStorage.getItem("auth") === "string"
        ? Promise.resolve()
        : Promise.reject(),
    logout: () => {
      const clearSession = () => {
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("permissions");
        sessionStorage.removeItem("profile");
        return Promise.resolve();
      };
      return axiosClient
        .post(`${apiUrl}/logout`)
        .then(clearSession, clearSession);
    },
    getIdentity: () => {
      const profile = sessionStorage.getItem("profile");
      if(profile) {
        const {id, fullName, avatar, roleNames} = JSON.parse(profile);
        return Promise.resolve({id, fullName, avatar, roleNames});
      }
      return Promise.resolve();
    },
    // authorization
    getPermissions: (params) => {
      const permissions = sessionStorage.getItem("permissions");
      if(permissions) {
        return Promise.resolve(JSON.parse(permissions));
      } 
      return Promise.resolve([]);
    },
  };
};

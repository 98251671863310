import React, { useEffect, useMemo, useState } from "react";
import {
  merge,
  mapValues,
  keysIn,
  pick,
  groupBy,
  map,
  get,
  sortBy,
  includes,
} from "lodash";
import {
  Button,
  CircularProgress,
  Typography,
  Tooltip,
  TextField as MUITextField,
  IconButton,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
  Grid,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AlarmIcon from "@material-ui/icons/Alarm";
import SaveIcon from "@material-ui/icons/Save";
import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ReferenceInput,
  SelectInput,
  EditButton,
  required,
  List,
  Datagrid,
  TextField,
  DateField,
  useRecordContext,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Link,
  TopToolbar,
  useGetList,
  Labeled,
  useQuery,
  useDataProvider,
  useNotify,
  useRefresh,
  useQueryWithStore,
  Loading,
  DateTimeInput,
  refreshSaga,
} from "react-admin";
import parse from "html-react-parser";
import ConfirmationDialogRaw from "../../components/ConfirmationDialogRaw";
import GestifyButton from "../../components/GestifyButton";
import GestifySplitButton from "../../components/GestifySplitButton";
import Subacciones from "./subacciones/subacciones";

const WarningTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.warning.light,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const TooltipTransaccion = ({ activa, children, ...props }) => {
  if (activa) {
    return <Tooltip {...props}>{children}</Tooltip>;
  }
  return <WarningTooltip {...props}>{children}</WarningTooltip>;
};

const BotonesEstado = (props) => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const record = useRecordContext(props);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [transiciones, setTransiciones] = useState(null);
  const [accion, setAccion] = useState(null);
 
  useEffect(() => {
    if (!record || !dataProvider) return;
    setAccion(null);
    dataProvider
      .getTransicionesParaCaso(record.id)
      .then(({ data }) => {
        setTransiciones(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [record, dataProvider]);

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <Typography color="error">{error}</Typography>;
  }
  if (!record) {
    return null;
  }
  if (!transiciones?.transiciones?.length) {
    return (
      <Typography color="textSecondary">
        No hay transiciones definidas!
      </Typography>
    );
  }

  const renderTransicion = (transicion, color) => (
    <TooltipTransaccion
      key={transicion.id}
      activa={transicion.activa}
      title={
        transicion.activa
          ? `${transicion.etiqueta_boton}`
          : parse(transicion?.errores?.join("<br />"))
      }
    >
      <span>
        <GestifyButton
          key={transicion.id}
          size="small"
          disabled={!transicion.activa || accion != null}
          color={color}
          variant="contained"
          style={{ marginLeft: 5 }}
          onClick={() => setAccion(transicion)}
        >
          {transicion.etiqueta_boton}
        </GestifyButton>
      </span>
    </TooltipTransaccion>
  );

  const groupsKeysOptions = {
    acciones_siguientes: {
      color: "success",
    },
    acciones_finales: {
      color: "error",
    },
    acciones_subestados: {
      color: "primary",
    },
  };

  const renderGroupComponents = (group, key) => {
    switch (key) {
      // Por si queremos cambiar el componente para renderizar un grupo de transiciones
      default:
        return map(group, (t) =>
          renderTransicion(t, get(groupsKeysOptions, `${key}.color`, "warning"))
        );
    }
  };

  const renderGroup = (group, key) => (
    <Grid key={key} item xs>
      {renderGroupComponents(group, key)}
    </Grid>
  );

  const renderGroups = (groups) => {
    // Por cada grupo
    return (
      <Grid
        container
        spacing={3}
        direction="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        {groups.acciones_siguientes &&
          renderGroup(groups.acciones_siguientes, "acciones_siguientes")}
        {map(groups, (transiciones, groupKey) => {
          if (includes(["acciones_siguientes", "acciones_finales"], groupKey)) {
            return null;
          }
          return renderGroup(groups[groupKey], groupKey);
        })}
        {groups.acciones_finales &&
          renderGroup(groups.acciones_finales, "acciones_finales")}
      </Grid>
    );
  };

  return (
    <>
      {renderGroups(groupBy(transiciones.transiciones, "grupo_transicion"))}
      {accion && (
        <TransicionConfirmationDialog
          accion={accion}
          record={record}
          onClose={() => {
            setAccion(null);
            refresh();
          }}
        />
      )}
    </>
  );
};

const OpcionesDefecto = {
  timeout: {
    activo: false,
    valor_inicial: 0,
  },
  comentario: {
    activo: false,
    valor_inicial: {
      texto: "Su comentario",
      mediodecontacto_id: 0,
    },
  },
  subestados: {
    activo: false,
    valor_inicial: {
      subestado_seleccionado: undefined,
    },
  },
};

const TransicionConfirmationDialog = ({ accion, record, onClose }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(accion != null);
  const [disableOkButton, setDisableOkButton] = useState(false);
  const opciones = useMemo(
    () =>
      merge(
        {},
        OpcionesDefecto,
        accion?.opciones ? JSON.parse(accion.opciones) : {}
      ),
    [accion]
  );

  const opcionesValoresIniciales = () =>
    mapValues(opciones, (v) => v.valor_inicial);

  const opcionesValoresReducer = (state, action) => ({
    ...state,
    [action.type]: action.payload,
  });
  const [opcionesValor, dispatchOpcionesValor] = React.useReducer(
    opcionesValoresReducer,
    opcionesValoresIniciales()
  );

  return (
    <ConfirmationDialogRaw
      id={`revolving-transicion-${record.id}`}
      title={accion?.etiqueta_boton}
      open={open}
      disableOkButton={disableOkButton}
      onClose={(isOk) => {
        setOpen(false);
        if (isOk) {
          // Aqui debemos de recolectar los datos del formulario para meterlos en el data
          return dataProvider
            .postTransicionParaCaso(record.id, accion.id, {
              // Enviamos solo los valores de las opciones activas
              data: pick(
                opcionesValor,
                keysIn(opciones).filter((k) => opciones[k].activo)
              ),
            })
            .then(({ data }) => {
              console.log("then", data);
              notify(`El revolving ha sido actualizado!`);
            })
            .catch((err) => {
              notify(`Hubo un error`, "warning");
            })
            .finally(onClose);
        }
        onClose();
      }}
    >
      <Typography variant="h6" style={{ textAlign: "center" }}>
        ¿Esta seguro de realizar la transición?
      </Typography>
      {/** Renderizamos todas las opciones con su key (nombre), contenido de opciones, el dispatch para cambiar el valor actual y los valores actuales */}
      {keysIn(opciones).map((key) => (
        <OpcionConfirmacion
          key={key}
          nombre={key}
          opcion={opciones[key]}
          dispatch={dispatchOpcionesValor}
          valores={opcionesValor}
          disableOkButton={disableOkButton}
          setDisableOkButton={setDisableOkButton}
        />
      ))}
    </ConfirmationDialogRaw>
  );
};

const OpcionConfirmacion = ({ nombre, opcion, ...others }) => {
  // Si la opcion es no activa o no es para el ui no la renderizamos
  if (!opcion?.activo) return null;

  // Rendericamos segun los soportados
  switch (nombre) {
    case "timeout":
      return (
        <OpcionIntervaloWithCalendar
          nombre={nombre}
          opcion={opcion}
          {...others}
        />
      );
    case "comentario":
      return <OpcionComentario nombre={nombre} opcion={opcion} {...others} />;
    case "subestados":
      return <OpcionSubestados nombre={nombre} opcion={opcion} {...others} />;
    case "establecer_fecha":
      return (
        <OpcionEstablecerFecha nombre={nombre} opcion={opcion} {...others} />
      );
    case "establecer_abogado_cobro":
      return (
        <OpcionEstablecerAbogado nombre={nombre} opcion={opcion} {...others} />
      );
    default:
      return null;
  }
};

const OpcionEstablecerFecha = ({
  nombre,
  opcion,
  dispatch,
  valores,
  setDisableOkButton,
}) => {
  // const [valor, setValor] = useState(valores[nombre]);

  // useEffect(() => {
  //   setDisableOkButton(true);
  // });

  useEffect(() => {
    console.log("valores", valores[nombre]);
    setDisableOkButton(valores[nombre] === undefined);
  }, [valores]);

  const fechaActual = valores[nombre] || new Date();

  const dateToPicker = (fecha) => {
    // console.log("fecha", fecha);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const days = fecha.getDate();

    // const pattern = `yyyy-MM-ddThh:mm`;

    const picker = `${year}-${`${month}`.padStart(2, "0")}-${`${days}`.padStart(
      2,
      "0"
    )}`;
    // console.log("picker", picker);
    return picker;
  };

  return (
    <div>
      <MUITextField
        type="date"
        label=""
        variant="outlined"
        helperText={opcion.texto}
        value={dateToPicker(fechaActual)}
        onChange={(event) => {
          console.log("onChange", event.target.value);
          dispatch({ type: nombre, payload: new Date(event.target.value) });
        }}
      />
    </div>
  );
};

const OpcionIntervaloWithCalendar = ({ nombre, opcion, dispatch, valores }) => {
  const [modoEdicion, setModoEdicion] = useState(false);
  const [valor, setValor] = useState(valores[nombre]);

  const saveNewValue = () => {
    setModoEdicion(false);
    dispatch({ type: nombre, payload: valor });
  };

  useEffect(() => {
    setValor(valores[nombre]);
  }, [valores]);

  const fechaActual = new Date(new Date().getTime() + valor * 3600 * 1000);

  const dateToPicker = (fecha) => {
    // console.log("fecha", fecha);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const days = fecha.getDate();
    const hours = fecha.getHours();
    const minutes = fecha.getMinutes();

    // const pattern = `yyyy-MM-ddThh:mm`;

    const picker = `${year}-${`${month}`.padStart(2, "0")}-${`${days}`.padStart(
      2,
      "0"
    )}T${hours}:${`${minutes}`.padStart(2, "0")}`;
    // console.log("picker", picker);
    return picker;
  };

  if (!opcion?.activo) return null;
  if (!modoEdicion) {
    return (
      <Typography>
        Se añadira automáticamente un aviso el{" "}
        <strong>{fechaActual.toLocaleString()}</strong>{" "}
        <IconButton
          color="primary"
          aria-label="Editar fecha"
          onClick={() => setModoEdicion(true)}
        >
          <AlarmIcon />
        </IconButton>
      </Typography>
    );
  } else {
    return (
      <div>
        <MUITextField
          type="datetime-local"
          label=""
          variant="outlined"
          helperText={`Se generará un aviso recordatorio`}
          value={dateToPicker(fechaActual)}
          onChange={(event) => {
            const newValor = Math.round(
              Math.abs(
                (new Date().getTime() -
                  new Date(event.target.value).getTime()) /
                  1000 /
                  3600
              )
            );
            // console.log("onChange", event.target.value, newValor);
            setValor(newValor);
          }}
        />
        <IconButton
          color="primary"
          aria-label="Actualizar valor"
          onClick={saveNewValue}
        >
          <SaveIcon />
        </IconButton>
      </div>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const OpcionComentario = ({ nombre, opcion, dispatch, valores }) => {
  const classes = useStyles();
  const [valor, setValor] = useState(valores[nombre]);

  useEffect(() => {
    setValor(valores[nombre]);
  }, [valores]);

  useEffect(() => {
    dispatch({ type: nombre, payload: valor });
  }, [valor]);

  const {
    loaded: medios_loaded,
    error: medios_error,
    data: medios,
  } = useQueryWithStore({
    type: "getList",
    resource: "mediosdecontacto",
    payload: { pagination: { page: 1, perPage: 1000 } },
  });

  if (!opcion?.activo) return null;
  return (
    <div>
      <div>
        <MUITextField
          className={classes.formControl}
          minRows={10}
          multiline
          fullWidth
          type="text"
          label="Comentario"
          variant="outlined"
          helperText={`Comentario aclaratorio`}
          value={valor.texto}
          onChange={(event) =>
            setValor({ ...valor, texto: event.target.value })
          }
          required
        />
      </div>

      <FormControl className={classes.formControl}>
        <InputLabel id="mediodecontacto-helper-label">
          Medio de contacto
        </InputLabel>
        <Select
          labelId="mediodecontacto-helper-label"
          id="mediodecontacto-helper"
          value={valor.mediodecontacto_id}
          onChange={(event) =>
            setValor({ ...valor, mediodecontacto_id: event.target.value })
          }
        >
          <MenuItem value={0}>
            <em>Desconocido</em>
          </MenuItem>
          {!medios_loaded && <Loading />}
          {medios_loaded &&
            !medios_error &&
            medios.map((m) => (
              <MenuItem key={m.id} value={m.id}>
                {m.nombre}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>Medio de contacto utilizado</FormHelperText>
      </FormControl>
    </div>
  );
};

const OpcionSubestados = ({
  nombre,
  opcion,
  dispatch,
  setDisableOkButton,
  valores,
}) => {
  const classes = useStyles();
  const [valor, setValor] = useState(valores[nombre]);
  const [subaccion, setSubaccion] = useState(undefined);

  useEffect(() => {
    setValor(valores[nombre]);
  }, [nombre, valores]);

  useEffect(() => {
    dispatch({ type: nombre, payload: valor });
    // console.log(valor);
    if (!opcion.acciones) {
      setSubaccion(undefined);
      setDisableOkButton(
        valor.subestado_seleccionado === undefined ||
          valor.subestado_seleccionado === "sin_seleccion"
      );
    } else {
      const indice_opcion = opcion.estados.indexOf(
        valor.subestado_seleccionado
      );
      if (opcion.acciones[indice_opcion]) {
        // Tenemos una accion asociada a este subestado
        setSubaccion(opcion.acciones[indice_opcion]);
      } else {
        setSubaccion(undefined);
        setDisableOkButton(
          valor.subestado_seleccionado === undefined ||
            valor.subestado_seleccionado === "sin_seleccion"
        );
      }
    }
  }, [dispatch, nombre, opcion.acciones, opcion.estados, setDisableOkButton, valor]);

  if (!opcion?.activo) return null;

  return (
    <div>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id="subestado-helper-label">
          Seleccione subestado
        </InputLabel>
        <Select
          labelId="subestado-helper-label"
          id="subestado-helper"
          value={valor.subestado_seleccionado || "sin_seleccion"}
          onChange={(event) =>
            setValor({ ...valor, subestado_seleccionado: event.target.value })
          }
          fullWidth
        >
          <MenuItem value="sin_seleccion">
            <em>No seleccionado aún</em>
          </MenuItem>
          {opcion.estados.map((m) => (
            <MenuItem key={m} value={m}>
              {m}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Sub estado o categoría</FormHelperText>
      </FormControl>
      {subaccion && (
        <Subacciones
          subaccion={subaccion}
          setDisableOkButton={setDisableOkButton}
          valor={valor}
          setValor={setValor}
          classes={classes}
        />
      )}
    </div>
  );
};

const OpcionEstablecerAbogado = ({
  nombre,
  opcion,
  dispatch,
  valores,
  setDisableOkButton,
}) => {
  const classes = useStyles();

  const roles_responsable = ["abogado", "abogado_responsable"];

  const { loading, data, ids, loaded } = useGetList(
    "usuarios",
    { page: 1, perPage: 1000 },
    {},
    {}
  );

  useEffect(() => {
    if (valores[nombre]?.responsable_id) {
      setDisableOkButton(false);
    } else {
      setDisableOkButton(true);
    }
  }, [valores]);

  if (loading) return <CircularProgress />;
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="responsable-helper-label">Resonsable</InputLabel>
        <Select
          labelId="responsable-helper-label"
          id="responsable-helper"
          value={valores[nombre]?.responsable_id || "0"}
          onChange={(event) => {
            dispatch({
              type: nombre,
              payload: { responsable_id: event.target.value },
            });
          }}
        >
          <MenuItem value={0}>
            <em>Sin responsable</em>
          </MenuItem>
          {!ids && <CircularProgress />}
          {ids &&
            ids
              .filter((id) => {
                return data[id].role_names.some(
                  (r) => roles_responsable.indexOf(r) !== -1
                );
              })
              .map((id) => (
                <MenuItem key={id} value={id}>
                  {data[id].name}
                </MenuItem>
              ))}
        </Select>
        <FormHelperText>Responsable asignado</FormHelperText>
      </FormControl>
    </div>
  );
};

export default BotonesEstado;

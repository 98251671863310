import React from "react";
import LogActivityCambioEstado from "./LogActivityCambioEstado";
import LogActivityCambioSubestado from "./LogActivityCambioSubestado";
import LogActivityComentario from "./LogActivityComentario";
import LogActivityContactoExterno from "./LogActivityContactoExterno";
import LogActivityLogFirmaDigital from "./LogActivityLogFirmaDigital";

const LogActivityComponent = ({ registro }) => {
  switch (registro.tipo_comentario) {
    case "comentario":
      return (
        <LogActivityComentario
          record={registro}
          key={`comentario_${registro.id}`}
        />
      );
    case "cambio_estado":
      return (
        <LogActivityCambioEstado
          record={registro}
          key={`cambio_estado_${registro.id}`}
        />
      );
    case "contacto_externo":
      return (
        <LogActivityContactoExterno
          record={registro}
          key={`contacto_externo_${registro.id}`}
        />
      );
    case "cambio_subestado":
      return (
        <LogActivityCambioSubestado
          record={registro}
          key={`cambio_subestado_${registro.id}`}
        />
      );
    case "firma_digital_log":
      return (
        <LogActivityLogFirmaDigital
          record={registro}
          key={`firma_digital_log_${registro.id}`}
        />
      );
    default:
      return null;
  }
};

export default LogActivityComponent;

import React from "react";
import { Typography } from "@material-ui/core";
import { TextField } from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useComentarioStyles = makeStyles({
  clamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
});

const LogActivityComentario = ({ record: registro }) => {
  const classes = useComentarioStyles();

  const mediante = () => (
    <>
      mediante&nbsp;[
      <TextField source="mediodecontacto.nombre" label="" record={registro} />]
    </>
  );

  return registro ? (
    <>
      <Typography
        variant="body2"
        gutterBottom
        component={RouterLink}
        to={`/comentarios/${registro.id}`}
        style={{ textDecoration: "none" }}
      >
        {`Comentario escrito por el usuario `}
        &nbsp;[
        {registro.user ? (
          <TextField source="user.name" label="" record={registro} />
        ) : (
          `desconocido`
        )}
        ]&nbsp;
        {registro.mediodecontacto_id && mediante()}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        gutterBottom
      ></Typography>

      <pre
        style={{
          display: "-webkit-box",
          wordWrap: 'break-word',
          flexWrap: 'wrap',
          minHeight: '100%',
          // maxWidth: "60vw",
          overflowX: "visible",
          overflowY: "visible",
          // width: "50vw",
        }}
      >
        {registro.texto}
      </pre>
    </>
  ) : null;
};
export default LogActivityComentario;

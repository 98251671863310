import { stringify } from "query-string";
import { fetchUtils, HttpError } from "ra-core";
import { chain, get } from "lodash";

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

/**
 * Build errors messages on validations if any
 *
 * @param {HttpError} httpError
 * @returns HttpError
 */
const processValidationErrorsMessages = (httpError) => {
  if (httpError instanceof HttpError && get(httpError, "body.errors")) {
    return Promise.reject(
      new HttpError(
        chain(httpError.body.errors)
          .toPairs()
          .map(([k, v]) => `${k}: ${v}`)
          .join("<br />")
          .value(),
        httpError.status,
        httpError.body
      )
    );
  }
  return Promise.reject(httpError);
};

const httpClientWithAuth = (url, options) => {
  const token = sessionStorage.getItem("auth");
  let newOptions = options ? { ...options } : {};
  if (token) {
    newOptions.user = {
      authenticated: true,
      token: `Bearer ${token}`,
    };
  }
  return fetchUtils.fetchJson(url, newOptions);
};

export default (
  apiUrl,
  httpClient = httpClientWithAuth,
  countHeader = "Content-Range"
) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
    const { field, order } = params.sort || {
      field: undefined,
      order: undefined,
    };

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      pagination: JSON.stringify({ page: page, perPage: perPage }),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options = {};
    return httpClient(url, options).then(({ headers, json }) => {
      // console.log(json);
      if (
        get(json, "data") === undefined ||
        get(json, "meta.total") === undefined
      ) {
        throw new Error("Error en la respuesta");
      }
      return {
        data: json.data,
        total: json.meta.total,
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json.data,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      pagination: JSON.stringify({ page: page, perPage: perPage }),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params?.filter,
        [params?.target]: params?.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options = {};

    return httpClient(url, options).then(({ headers, json }) => {
      return {
        data: json?.data,
        total: json?.meta?.total,
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params?.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({ data: json }))
      .catch(processValidationErrorsMessages),

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json?.id) })),

  create: (resource, params) => {
    const defaultCreateFn = (data) =>
      httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then(({ json }) => {
          return { data: { ...json, id: json?.id } };
        })
        .catch(processValidationErrorsMessages);

    if (resource === "documentos" && params.data.documento_file) {
      // Freshly dropped pictures are File objects and must be converted to base64 strings
      const newDocument = params.data.documento_file;
      return convertFileToBase64(newDocument)
        .then((base64File) => ({
          src: base64File,
          nombre: `${newDocument?.Nombre}`,
        }))
        .then((encodedFile) => {
          return defaultCreateFn({
            ...params?.data,
            fichero: encodedFile,
          });
        });
    }
    // Return default
    return defaultCreateFn(params?.data);
  },
  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params?.id}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "text/plain",
      }),
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
          headers: new Headers({
            "Content-Type": "text/plain",
          }),
        })
      )
    ).then((responses) => ({
      data: responses.map(({ json }) => json?.id),
    })),

  //************************************************************** */
  // Custom rest api calls for our own api, not ra-resources

  /**
   *
   * @param {*} caso_id
   * @returns
   */
  getTransicionesParaCaso: (caso_id) =>
    httpClient(`${apiUrl}/transiciones/caso/${caso_id}`)
      .then(({ json }) => ({
        data: json,
      }))
      .catch(processValidationErrorsMessages),
  postTransicionParaCaso: (caso_id, transicion_id, params) =>
    httpClient(
      `${apiUrl}/transiciones/caso/${caso_id}/transicion/${transicion_id}`,
      {
        method: "POST",
        body: JSON.stringify(params.data),
      }
    )
      .then(({ json }) => {
        if (json?.download_url) {
          setTimeout(function(){
            window.open(json.download_url, "_blank");
          }, 100);
        }
        return { data: { ...json, id: json?.id } };
      })
      .catch(processValidationErrorsMessages),
});

import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  required,
  BooleanField,
  BooleanInput,
  FileInput,
  List,
  TextField,
  Datagrid,
  TopToolbar,
  ListButton,
  ShowButton,
} from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const BaseFieldsTipoDocumento = ({esEdit = false}) => {
  return (
    <>
      <TextInput
        source="nombre"
        required
        helperText="Texto para listados y vistas"
        fullWidth
      />
      <br />
      <TextInput
        disabled={esEdit}
        source="valor"
        required
        label="Identificador del documento"
        helperText="texto para identificación tiene que ser único"
      />
      <BooleanInput
        required
        source="es_personal_al_precontacto"
        label="Es un documento del precontacto"
        helperText="El documento se almacena en la carpeta del contacto no del revolving."
      />
      <BooleanInput
        required
        source="requiere_fecha_efecto"
        label="Requiere fecha efecto"
        helperText="Obliga a introducir una fecha que tomaremos como de efecto del documento cargado"
      />
     
    </>
  );
};

const CommonActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label="Back"
      icon={<ChevronLeft />}
      variant="contained"
      color="secondary"
    />
  </TopToolbar>
);

export const TipodocumentosCreate = (props) => (
  <Create actions={<CommonActions />} {...props}>
    <SimpleForm>
      <BaseFieldsTipoDocumento esEdit={false} />
    </SimpleForm>
  </Create>
);

export const TipodocumentosEdit = (props) => (
  <Edit actions={<CommonActions />} {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <BaseFieldsTipoDocumento esEdit={true} />
    </SimpleForm>
  </Edit>
);

export const TipodocumentoList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      {/* <DateField source="created_at" />
      <DateField source="updated_at" /> */}
      <TextField source="nombre" />
      <TextField source="valor" />
      <BooleanField source="es_personal_al_precontacto" looseValue={true} />
      <BooleanField source="requiere_fecha_efecto" looseValue={true} />
     
    </Datagrid>
  </List>
);

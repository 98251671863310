import "./App.css";

import { Admin, Resource, ListGuesser } from "react-admin";
import {
  apiProvider as apiClient,
  authProvider as authClient,
} from "./api-provider";

import { OrigenleadCreate, OrigenleadEdit } from "./resources/origen-leads";
import {
  MediosdecontactoCreate,
  MediosdecontactoEdit,
} from "./resources/medios-de-contacto";
import { LeadCreate, LeadList, LeadShow } from "./resources/leads";
import { PrecontactoEdit, PrecontactoCreate } from "./resources/precontactos";

import {
  CasosList,
  CasosShow,
  CasosCreate,
  CasosEdit,
} from "./resources/casos";
import {
  ComentarioEdit,
  ComentarioCreate,
} from "./resources/comentarios/comentarios";
import {
  TipodocumentosCreate,
  TipodocumentosEdit,
  TipodocumentoList,
} from "./resources/tipodocumentos/tipodocumentos";
import {
  CrearDocumentoPorCaso,
  EditarDocumentoPorCaso,
} from "./resources/documentos";
import {
  UsuariosCreate,
  UsuariosEdit,
  UsuarioList,
} from "./resources/usuarios/usuarios";
import { includes } from "lodash";

import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PeopleIcon from "@material-ui/icons/People";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import GestifyLayout from "./components/GestifyLayout";
import GestifyTheme from "./components/GestifyTheme";
import GestifyLoginPage from "./components/GestifyLoginPage";
import { RolesList } from "./resources/roles/roles";
import {
  EntidadeList,
  EntidadesCreate,
  EntidadesEdit,
} from "./resources/entidades";
import Dashboard from "./dashboard/dashboard";

const dataProvider = apiClient(process.env.REACT_APP_API_URL);
const authProvider = authClient(process.env.REACT_APP_URL);

function App() {
  return (
    <Admin
      disableTelemetry
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={GestifyLayout}
      theme={GestifyTheme}
      loginPage={GestifyLoginPage}
      dashboard={Dashboard}
    >
      {(permissions) => [
        <Resource
          icon={FolderOpenIcon}
          name="casos"
          list={CasosList}
          show={CasosShow}
          edit={CasosEdit}
          create={CasosCreate}
          options={{ label: "Revolving" }}
        />,

        <Resource
          icon={ImportContactsIcon}
          name="leads"
          list={LeadList}
          create={LeadCreate}
          show={LeadShow}
          options={{ label: "Leads" }}
        />,

        <Resource
          icon={AccountBalanceIcon}
          name="entidades"
          list={EntidadeList}
          create={EntidadesCreate}
          edit={EntidadesEdit}
          options={{ label: "Entidades Bancarias" }}
        />,

        <Resource
          icon={AccountTreeIcon}
          name="origenesleads"
          list={ListGuesser}
          create={OrigenleadCreate}
          edit={OrigenleadEdit}
          options={{ label: "Origen de Leads" }}
        />,
        <Resource
          icon={SettingsInputComponentIcon}
          name="mediosdecontacto"
          list={ListGuesser}
          create={MediosdecontactoCreate}
          edit={MediosdecontactoEdit}
          options={{ label: "Medios de contacto" }}
        />,
        <Resource
          icon={DescriptionIcon}
          name="tipodocumentos"
          list={TipodocumentoList}
          create={TipodocumentosCreate}
          edit={TipodocumentosEdit}
          options={{ label: "Tipo de Documentos" }}
        />,
        <Resource
          icon={PeopleIcon}
          name="usuarios"
          create={includes(permissions, "view_users") ? UsuariosCreate : null}
          edit={includes(permissions, "view_users") ? UsuariosEdit : null}
          list={includes(permissions, "view_users") ? UsuarioList : null}
        />,
        includes(permissions, "view_roles") ? <Resource name="roles" /> : null,
        <Resource
          name="precontactos"
          edit={PrecontactoEdit}
          show={PrecontactoEdit}
          create={PrecontactoCreate}
        />,

        <Resource
          name="comentarios"
          create={ComentarioCreate}
          edit={ComentarioEdit}
        />,

        <Resource
          name="documentos"
          create={CrearDocumentoPorCaso}
          edit={EditarDocumentoPorCaso}
        />,
      ]}
    </Admin>
  );
}

export default App;

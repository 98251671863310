import React from "react";
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Link,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Link as RouterLink } from "react-router-dom";
import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ReferenceInput,
  SelectInput,
  required,
  List,
  Datagrid,
  DateField,
  Show,
  SimpleShowLayout,
  FormWithRedirect,
  Labeled,
  BooleanField,
  BooleanInput,
  Toolbar,
  useRedirect,
  TopToolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import { TextFieldWithEmptyText } from "../../components/TextFieldWithEmptyText";

import { withRouter } from "react-router";
import { BackButton } from "../../components/BackButton";
import { CreateRelatedCasoButton } from "../leads";

export const PrecontactoLeadCreate = () => (
  <>
    <p>Datos del contacto</p>
    <TextInput source="precontacto.nombre_alias" fullWidth label="Alias" />
    <BooleanInput
      label="Principal"
      source="precontacto.principal"
      initialValue={true}
      disabled
    />
    <br />
    <TextInput source="precontacto.nombre" label="Nombre" />
    &nbsp;&nbsp;&nbsp;&nbsp;
    <TextInput source="precontacto.apellidos" label="Apellidos" />
    <br />
    <TextInput source="precontacto.email" label="E-Mail" />
    &nbsp;&nbsp;&nbsp;&nbsp;
    <TextInput source="precontacto.otro_email" label="Otro E-Mail" />
    &nbsp;&nbsp;&nbsp;&nbsp;
    <TextInput source="precontacto.telefono" label="Telefono" />
    &nbsp;&nbsp;&nbsp;&nbsp;
    <TextInput source="precontacto.otro_telefono" label="Otro Telefono" />
  </>
);

export const PrecontactoDetailsEdit = ({ record, canBePrincipal = false }) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="h6" gutterBottom>
      Precontacto
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        <Labeled source="Alias" resource="precontacto" fullWidth>
          <TextInput
            fullWidth
            source="nombre_alias"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Labeled source="Nombre" resource="precontacto" fullWidth>
          <TextInput
            source="nombre"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Labeled source="Apellidos" resource="precontacto" fullWidth>
          <TextInput
            source="apellidos"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Labeled source="E-Mail" resource="precontacto" fullWidth>
          <TextInput
            source="email"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Labeled source="Otro E-Mail" resource="precontacto" fullWidth>
          <TextInput
            source="otro_email"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Labeled source="Teléfono" resource="precontacto" fullWidth>
          <TextInput
            source="telefono"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Labeled source="Otro Teléfono" resource="precontacto" fullWidth>
          <TextInput
            source="otro_telefono"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Labeled source="Principal" resource="precontacto" fullWidth>
          <BooleanInput
            source="principal"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
            disabled={!canBePrincipal}
          />
        </Labeled>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Labeled source="Tipo de documento">
          <SelectInput
            options={{ label: "" }}
            source="tipo_documento_identidad"
            choices={[
              { id: "DNI", name: "DNI" },
              { id: "NIE", name: "NIE" },
              { id: "Pasaporte", name: "Pasaporte" },
              { id: "Otros", name: "Otros" },
            ]}
          />
        </Labeled>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Labeled source="Documento identidad" resource="precontacto">
          <TextInput
            source="documento_identidad"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
        <br />
        <Labeled source="Profesión" resource="precontacto" fullWidth>
          <TextInput
            source="profesion"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
        <Labeled source="Estado civil">
          <SelectInput
            options={{ label: "" }}
            source="estado_civil"
            choices={[
              { id: "soltero", name: "Soltero" },
              { id: "soltera", name: "Soltera" },
              { id: "casado", name: "Casado" },
              { id: "casada", name: "Casada" },
              { id: "pareja_hecho", name: "Pareja de hecho" },
              { id: "viudo", name: "Viudo" },
              { id: "viuda", name: "Viuda" },
              { id: "divorciado", name: "Divorciado" },
              { id: "divorciada", name: "Divorciada" },
            ]}
          />
        </Labeled>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Labeled source="Sexo">
          <SelectInput
            options={{ label: "" }}
            source="sexo"
            choices={[
              { id: "hombre", name: "Hombre" },
              { id: "mujer", name: "Mujer" },
            ]}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Labeled source="Dirección" resource="precontacto" fullWidth>
          <TextInput
            source="direccion_1"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
        <Labeled source="Dirección adicional" resource="precontacto" fullWidth>
          <TextInput
            source="direccion_2"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
        <Labeled source="Código Postal" resource="precontacto">
          <TextInput
            source="codigo_postal"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
            // format={(v) => (v != null ? v.toString().padStart(5, '0') : '')}
            // parse={(v) => v ? parseInt(v) : ''}
          />
        </Labeled>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Labeled source="Municipio" resource="precontacto">
          <TextInput
            source="municipio"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Labeled source="Provincia" resource="precontacto">
          <TextInput
            source="provincia"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Labeled source="Comunidad Autonoma" resource="precontacto">
          <TextInput
            source="comunidad_autonoma"
            resource="precontacto"
            record={record}
            options={{ label: "" }}
          />
        </Labeled>
      </Grid>
    </Grid>
  </Box>
);

export const PrecontactoDetailsShow = ({ record }) => {
  if (!record) return <></>;
  return (
    <Box display="flex" flexDirection="column">
      <Typography style={{ textDecoration: "none" }}>
        Datos de contacto
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            component={RouterLink}
            color="primary"
            to={`/precontactos/${record?.id}`}
            style={{ textDecoration: "none" }}
          >
            {record?.nombre} {record?.apellidos}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled source="Alias" resource="precontactos" fullWidth>
            <TextFieldWithEmptyText
              fullWidth
              source="nombre_alias"
              resource="precontactos"
              record={record}
              options={{ label: "" }}
            />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Labeled source="E-Mail" resource="precontactos" fullWidth>
            <TextFieldWithEmptyText
              source="email"
              resource="precontactos"
              record={record}
              options={{ label: "" }}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Labeled source="Otro E-Mail" resource="precontactos" fullWidth>
            <TextFieldWithEmptyText
              source="otro_email"
              resource="precontactos"
              record={record}
              options={{ label: "" }}
            />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Labeled source="Teléfono" resource="precontactos" fullWidth>
            <TextFieldWithEmptyText
              source="telefono"
              resource="precontactos"
              record={record}
              options={{ label: "" }}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Labeled source="Otro Teléfono" resource="precontacto" fullWidth>
            <TextFieldWithEmptyText
              source="otro_telefono"
              resource="precontacto"
              record={record}
              options={{ label: "" }}
            />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Labeled source="Principal" resource="precontacto" fullWidth>
            <BooleanInput
              source="principal"
              resource="precontacto"
              record={record}
              options={{ label: "" }}
              disabled
            />
          </Labeled>
        </Grid>
      </Grid>
    </Box>
  );
};

export const PrecontactoShowCard = ({
  title,
  precontacto,
  createRelatedCasoButtonHide = false,
}) => {
  return (
    <Box p={2}>
      <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
        {title}
      </Typography>
      <Typography
        component={RouterLink}
        color="primary"
        to={`/precontactos/${precontacto?.id}`}
        style={{ textDecoration: "none" }}
        variant="body2"
      >
        {precontacto?.nombre_completo ||
          precontacto?.nombre_alias ||
          `Desconocido`}
      </Typography>
      <Typography variant="body2">
        {precontacto?.nombre_alias}
        <br />
        {precontacto?.telefono || "-"}&nbsp;/&nbsp;
        {precontacto?.otro_telefono || "-"}
        <br />
        {precontacto?.email || "-"}&nbsp;/&nbsp;
        {precontacto?.otro_email || "-"}
      </Typography>
      {!createRelatedCasoButtonHide && (
        <CreateRelatedCasoButton record={precontacto} />
      )}
    </Box>
  );
};

const PrecontactoActions = (props) => {
  return (
    <TopToolbar>
      <BackButton />
    </TopToolbar>
  );
};

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const PrecontactoToolbarEdit = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);

const PrecontactoEditInner = ({
  history: { goBack },
  staticContext,
  ...props
}) => {
  const onSuccess = () => goBack();

  return (
    <Edit
      {...props}
      title="Editar precontacto"
      mutationMode="optimistic"
      onSuccess={onSuccess}
      actions={<PrecontactoActions />}
    >
      <SimpleForm warnWhenUnsavedChanges toolbar={<PrecontactoToolbarEdit />}>
        <PrecontactoDetailsEdit />
      </SimpleForm>
    </Edit>
  );
};

export const PrecontactoEdit = withRouter(PrecontactoEditInner);

const PrecontactoCreateInner = ({
  history: { goBack },
  location,
  staticContext,
  ...props
}) => {
  const onSuccess = () => goBack();
  return (
    <Create
      {...props}
      title="Crear precontacto para este lead"
      onSuccess={onSuccess}
      actions={<PrecontactoActions />}
    >
      <SimpleForm warnWhenUnsavedChanges>
        <PrecontactoDetailsEdit />
      </SimpleForm>
    </Create>
  );
};

export const PrecontactoCreate = withRouter(PrecontactoCreateInner);

import * as React from "react";
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined";
import { DateTimeInput, FilterList, FilterListItem, useGetList, useListFilterContext, useTranslate } from "react-admin";
import { Form } from 'react-final-form';
import { EstadoCaso, SubestadoCaso } from "../../helpers/helpers";

// const ComercialFilter = ({ label, source }) => {
//   const { loading, data, ids, loaded } = useGetList(
//     "usuarios",
//     { page: 1, perPage: 1000 },
//     {},
//     { role: "comercial" }
//   );
//   if (loading || !ids || !loaded) {
//     return <CircularProgress />;
//   }
//   console.log(data);
//   return (
//     <SelectField
//       label={label}
//       source={source}
//       choices={ids.map((id) => ({
//         id: data[id].id,
//         name: data[id].name,
//       }))}
//     />
//   );
// };

export const ComercialAsignadoFilter = () => {
  const { loading, data, ids, loaded } = useGetList(
    "usuarios",
    { page: 1, perPage: 1000 },
    {},
    { role: "comercial" }
  );

  return (
    <FilterList label="Comercial Asignado" icon={<LocalOfferIcon />}>
      {ids.map((id, idx) => (
        <FilterListItem
          label={data[id].name}
          key={id}
          value={{ ["comercial_asignado_id[" + idx + "]"]: id }}
        />
      ))}
    </FilterList>
  );
};

export const AbogadoAsignadoFilter = () => {
    const { loading, data, ids, loaded } = useGetList(
      "usuarios",
      { page: 1, perPage: 1000 },
      {},
      { role: "abogado" }
    );
  
    return (
      <FilterList label="Abogado Asignado" icon={<LocalOfferIcon />}>
        {ids.map((id, idx) => (
          <FilterListItem
            label={data[id].name}
            key={id}
            value={{ ["abogado_asignado_id[" + idx + "]"]: id }}
          />
        ))}
      </FilterList>
    );
  };
  
  export const OrigenFilter = () => {
    const { loading, data, ids, loaded } = useGetList(
      "origenesleads",
      { page: 1, perPage: 1000 },
      {},
      {}
    );
    if(loading) {
      return null;
    }
    return (
      <FilterList label="Origen" icon={<LocalOfferIcon />}>
        {ids.map((id, idx) => (
          <FilterListItem
            label={data[id].nombre}
            key={id}
            value={{ ["origenlead_id[" + idx + "]"]: id }}
          />
        ))}
      </FilterList>
    );
  };


export const EstadoFilter = () => {
  return (
    <FilterList label="Estado" icon={<LocalOfferIcon />}>
      {EstadoCaso.getEstadosCaso().map((estado, idx) => (
        <FilterListItem
          label={estado.toString()}
          key={estado.name}
          value={{ ["estado[" + idx + "]"]: estado.name }}
        />
      ))}
    </FilterList>
  );
};

export const SubestadoFilter = () => {
  return (
    <FilterList label="Subestado" icon={<LocalOfferIcon />}>
      {SubestadoCaso.getSubestadosCaso().map((subestado, idx) => (
        <FilterListItem
          label={subestado.toString()}
          key={subestado.name}
          value={{ ["subestado[" + idx + "]"]: subestado.name }}
        />
      ))}
    </FilterList>
  );
};



export const FilterDateTimeSearch = (props) => {
    const { source = 'created_at', ...rest } = props;
    const { filterValues, setFilters } = useListFilterContext();
    const translate = useTranslate();

    const onSearchChange = (event) => {
        if (event.target) {
            setFilters({ ...filterValues, [source]: event.target.value }, null);
        } else {
            const { [source]: _, ...filters } = filterValues;
            setFilters(filters, null);
        }
    };

    const initialValues = React.useMemo(
        () => ({
            [source]: filterValues[source],
        }),
        [filterValues, source]
    );

    const onSubmit = () => undefined;

    return (
        <Form initialValues={initialValues} onSubmit={onSubmit}>
            {() => (
                <DateTimeInput
                    helperText={false}
                    source={source}
                    label={translate('ra.action.search')}
                    // InputProps={{
                    //     endAdornment: (
                    //         <InputAdornment position="end">
                    //             <SearchIcon color="disabled" />
                    //         </InputAdornment>
                    //     ),
                    // }}
                    onChange={onSearchChange}
                    {...rest}
                />
            )}
        </Form>
    );
};


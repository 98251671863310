import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  required,
  BooleanField,
  BooleanInput,
  FileInput,
  List,
  TextField,
  Datagrid,
  TopToolbar,
  ListButton,
  ShowButton,
  Filter,
} from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const BaseFieldsEntidades = ({ esEdit = false }) => {
  return (
    <>
      <TextInput
        source="nombre"
        required
        helperText="Nombre entidad para listados"
        fullWidth
      />

      <TextInput
        source="razon_social"
        required
        helperText="Razon social"
        fullWidth
      />

      <TextInput
        source="calle_numero_piso"
        required
        helperText="Calle Número y piso"
        fullWidth
      />

      <TextInput source="codigo_postal" required helperText="Código postal" />
      &nbsp;&nbsp;
      <TextInput source="localidad" required helperText="Localidad" />
      &nbsp;&nbsp;
      <TextInput 
        source="correo_electronico" 
        required 
        helperText="E-Mail" 
        style={{width: '20em'}}
    />
    </>
  );
};

const CommonActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label="Back"
      icon={<ChevronLeft />}
      variant="contained"
      color="secondary"
    />
  </TopToolbar>
);

export const EntidadesCreate = (props) => (
  <Create actions={<CommonActions />} {...props}>
    <SimpleForm>
      <BaseFieldsEntidades esEdit={false} />
    </SimpleForm>
  </Create>
);

export const EntidadesEdit = (props) => (
  <Edit actions={<CommonActions />} {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <BaseFieldsEntidades esEdit={true} />
    </SimpleForm>
  </Edit>
);

const EntidadFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="q" alwaysOn />
    </Filter>
);

export const EntidadeList = (props) => (
  <List {...props} filters={<EntidadFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="nombre" />
      <TextField source="razon_social" />
      <TextField source="calle_numero_piso" />
      <TextField source="codigo_postal" />
      <TextField source="localidad" />
      <TextField source="correo_electronico" />
    </Datagrid>
  </List>
);

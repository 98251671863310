import React, { useCallback, useMemo, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  CircularProgress,
  Container,
  Chip,
  Card as MuiCard,
  CardContent,
  withStyles,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";
import { FilterList } from "@material-ui/icons";

import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ReferenceInput,
  SelectInput,
  EditButton,
  required,
  List,
  Datagrid,
  TextField,
  DateField,
  useRecordContext,
  Show,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  useGetList,
  Labeled,
  useQuery,
  FunctionField,
  ListButton,
  ReferenceField,
  Filter,
  useGetIdentity,
  useTranslate,
  SelectField,
  FilterLiveSearch,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  Pagination,
  useRedirect,
  sanitizeListRestProps,
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,

} from "react-admin";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { PrecontactoShowCard } from "../precontactos/precontactos";
import { BackButton } from "../../components/BackButton";
import { ListadoDocumentosPorCaso } from "../documentos";
import BotonesEstado from "./botones-estado";
import LogActivityIcon from "../../components/LogActivityIcon";
import LogActivityComponent from "../../components/LogActivityComponent";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {
  casoPuedeEditarExpediente,
  esRole,
  EstadoCaso,
} from "../../helpers/helpers";
import {
  AbogadoAsignadoFilter,
  ComercialAsignadoFilter,
  EstadoFilter,
  FilterDateTimeSearch,
  OrigenFilter,
  SubestadoFilter,
} from "./filters";
import { isArray, isBoolean, pick } from "lodash";
import FolderIcon from "@material-ui/icons/Folder";

const useCasosStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: "bold",
  },
  stepper: {
    background: "none",
    border: "none",
    marginLeft: "0.3em",
  },
  labelBoldRight: {
    fontWeight: "bold",
    textAlign: "right",
    paddingRight: "1em",
  },
}));

export const ComercialAsignadoInput = ({
  source,
  label = "Comercial asignado",
}) => {
  const { loading, data, ids, loaded } = useGetList(
    "usuarios",
    { page: 1, perPage: 1000 },
    {},
    { role: "comercial" }
  );
  if (loading) return <CircularProgress />;
  return (
    <SelectInput
      options={{ label }}
      style={{ width: "20em" }}
      source={source}
      choices={ids.map((id) => ({ id: id, name: data[id].name }))}
    />
  );
};

export const AbogadoAsignadoInput = ({
  source,
  label = "Abogado asignado",
}) => {
  const { loading, data, ids, loaded } = useGetList(
    "usuarios",
    { page: 1, perPage: 1000 },
    {},
    { role: "abogado" }
  );
  if (loading) return <CircularProgress />;
  return (
    <SelectInput
      options={{ label }}
      style={{ width: "20em" }}
      source={source}
      choices={ids.map((id) => ({ id: id, name: data[id].name }))}
    />
  );
};

export const CasoBaseCreate = (props) => (
  <>
    <p>Detalles del revolving</p>
    <TextInput
      source="caso.asunto"
      label="Asunto del revolving"
      fullWidth
      helperText="Descripción del revolving"
    />
    <ReferenceInput
      label="Origen lead"
      source="caso.origenlead_id"
      reference="origenesleads"
      validate={[required()]}
      filter={{ q_invertido: "%Excel%" }}
      // filterToQuery={searchText => ({ q_invertido: searchText })}
    >
      <SelectInput optionText="nombre" />
    </ReferenceInput>
    <br />
    <ComercialAsignadoInput source="caso.comercial_asignado_id" />
    <br />
    <AbogadoAsignadoInput source="caso.abogado_asignado_id" />
  </>
);

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "25em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const FilterSidebar = (props) => {
  return (
    <Card>
      <CardHeader
        title="Filtros"
        action={
          <IconButton
            aria-label="limpiar filtros"
            onClick={() => {
              props.setFilters({ filter: null, order: null, sort: null });
            }}
          >
            <ClearIcon />
          </IconButton>
        }
      />
      <CardContent>
        <FilterLiveSearch source="q" label="Buscar..." />
        <FilterLiveSearch source="id" label="Buscar revolving id..." />
        <FilterLiveSearch source="lead_id" label="Buscar lead id..." />
        <div>
          <Typography variant="h5">Fecha entrada</Typography>
          <FilterDateTimeSearch source="fecha_entrada_gte" label="Desde" />
          <FilterDateTimeSearch source="fecha_entrada_lte" label="Hasta" />
        </div>
        <div>
          <Typography variant="h5">Aviso</Typography>
          <FilterDateTimeSearch source="timeout_gte" label="Desde" />
          <FilterDateTimeSearch source="timeout_lte" label="Hasta" />
        </div>
        <ComercialAsignadoFilter />
        <AbogadoAsignadoFilter />
        <OrigenFilter />
        <EstadoFilter />
        <SubestadoFilter />
      </CardContent>
    </Card>
  );
};

const CasosPagination = (props) => (
  <Pagination
    rowsPerPageOptions={[10, 25, 50, 100, 200, 300, 400, 500]}
    {...props}
  />
);

const BulkActionsCasosList = () => <></>

export const CasosList = ({ permissions, ...props }) => {
  const { identity, loading: identityLoading } = useGetIdentity();
  const [allOrMe, setAllOrMe] = useState("me");

  const handleAllOrMe = (event, newValue) => {
    setAllOrMe(newValue);
  };

  if (identityLoading || !identity) {
    return <CircularProgress />;
  }

  const defaultFilters = esRole(identity, "comercial")
    ? {
        comercial_asignado_id: allOrMe === "me" ? identity.id : undefined,
      }
    : esRole(identity, "abogado")
    ? {
        abogado_asignado_id: allOrMe === "me" ? identity.id : undefined,
      }
    : {};

  return (
    <>
      {/* {esRole(identity, "comercial") && (
        <ToggleButtonGroup
          value={allOrMe}
          exclusive
          onChange={handleAllOrMe}
          aria-label="Filtrar revolving"
        >
          <ToggleButton value="all" aria-label="Todos">
            Todos
          </ToggleButton>
          <ToggleButton value="me" aria-label="Mios">
            Solo mios
          </ToggleButton>
        </ToggleButtonGroup>
      )} */}
      <List
        {...props}
        title="Lista de revolvings"
        // filters={<CasosFilter />}
        filterDefaultValues={{ ...defaultFilters }}
        aside={<FilterSidebar />}
        pagination={<CasosPagination />}
        bulkActionButtons={<BulkActionsCasosList />}
      >
        <Datagrid rowClick="show" >
          <TextField source="lead.id" label="Lead ID" sortable={false} />
          <TextField
            source="precontacto.nombre_completo"
            label="Nombre Completo"
            sortable={false}
          />
          <TextField source="expediente" label="Num Expediente" />
          {/* entidad */}
          <FunctionField
            source="estado"
            label="Estado"
            render={(record) =>
              EstadoCaso.fromString(record.estado)?.toString()
            }
          />
          <DateField
            showTime={true}
            source="fecha_entrada_estado_actual"
            label="Estado desde"
          />
          <TextField source="subestado" label="Subestado" />
          <DateField
            showTime={true}
            source="fecha_entrada_subestado_actual"
            label="Subestado desde"
          />
          <ReferenceField
            label="Comercial"
            source="comercial_asignado_id"
            reference="usuarios"
          >
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField
            label="Abogado"
            source="abogado_asignado_id"
            reference="usuarios"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="semana_estudio_juridico" label="Semana" />
          <FunctionField
            source="timeout"
            label="Aviso"
            render={(record) =>
              record.timeout
                ? new Date(record.timeout * 1000).toLocaleString()
                : ``
            }
          />
          <TextField
            source="precontacto.email"
            label="E-Mail"
            sortable={false}
          />
          <TextField
            source="precontacto.telefono"
            label="Teléfono"
            sortable={false}
          />

          <TextField
            source="precontacto.nombre_alias"
            label="Alias"
            sortable={false}
          />

          <TextField source="origenlead.nombre" label="Origen" />
          <DateField
            showTime={true}
            source="fecha_entrada"
            label="F. Entrada"
          />
          <TextField
            source="intentos"
            label="Nº Reclamaciones"
            sortable={true}
          />
          <TextField source="id" label="Revolving ID" />
        </Datagrid>
      </List>
    </>
  );
};

const LeadShowDescription = ({ actions }) => {
  const record = useRecordContext();
  if (!record) return <></>;
  return (
    <Box p={1} m={1}>
      <Grid container>
        <Grid item xs={3}>
          <Typography
            color="textPrimary"
            style={{
              textDecoration: "none",
              margin: "20px",
              paddingTop: "10px",
            }}
            variant="h4"
          >
            REV-{record.id}
          </Typography>
          <Typography
            component={RouterLink}
            color="primary"
            to={`/leads/${record.lead?.id}/show`}
            style={{
              textDecoration: "none",
              margin: "20px",
              paddingTop: "10px",
            }}
            variant="h6"
          >
            LEAD-{record.lead?.id}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          {actions}
        </Grid>
        <Grid item xs={12}>
          <PrecontactoShowCard
            title="Contacto principal"
            precontacto={record.lead?.precontacto}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const AvisoExpediente = () => {
  const record = useRecordContext();
  if (!record) return null;
  if (!record.timeout) return null;
  const severity =
    new Date(parseInt(record.timeout * 1000)) < new Date() ? `warning` : `info`;
  return (
    <Alert severity={severity}>
      El revolving tiene un aviso activo para él —{" "}
      <strong>
        {new Date(parseInt(record.timeout * 1000)).toLocaleString()}
      </strong>
    </Alert>
  );
};

const AvisoEstado = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Alert severity="success">
      <strong>{EstadoCaso.fromString(record.estado)?.toString()}</strong>
    </Alert>
  );
};

const CasoShowDescripcion = () => {
  const classes = useCasosStyles();
  const record = useRecordContext();
  const xsSizeCol1 = 3;
  const xsSizeCol2 = 12 - xsSizeCol1;
  if (!record) return null;
  return (
    <Box p={1}>
      <Grid container>
        <Grid item xs={5}>
          <AvisoExpediente />
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <EditButton
            basePath="/casos"
            record={record}
            variant="contained"
            color="primary"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <PrecontactoShowCard
            title="Contacto"
            precontacto={record.precontacto}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Estado actual:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              <AvisoEstado />
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Subestado actual:
              </Typography>
            </Grid>
            <Grid
              item
              xs={xsSizeCol2}
              style={{
                lineHeight: "3em",
                textAlign: "left",
                backgroundColor: record?.subestado ? "orange" : "white",
              }}
            >
              <Typography variant="body1">
                {(record?.subestado && <strong>{record?.subestado}</strong>) ||
                  "-"}
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Reclamaciones:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              {record?.intentos || `-`}
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Fecha entrada:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              <Typography variant="body1">
                {new Date(record.fecha_entrada).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Fecha presentación demanda:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              <Typography variant="body1">
                {record.fecha_presentacion_demanda
                  ? new Date(record.fecha_presentacion_demanda).toLocaleString()
                  : `-`}
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Origen:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              <Typography variant="body1">
                {record.origenlead?.nombre}
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Número expediente:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              <Typography variant="body1">
                {record.expediente || `-`}
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Tipo producto:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              <Typography variant="body1">
                {record.tipo_producto || `-`}
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Identificación del producto:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              {record.identificacion_producto || `-`}
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Entidad:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              <ReferenceField
                source="entidad_id"
                reference="entidades"
                label=""
              >
                <TextField source="nombre" />
              </ReferenceField>
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Forma contratación:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              {record.forma_contratacion || `-`}
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Año de contratación:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              {record.anyo_contrato || `-`}
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                TAE:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              {record.TAE || `-`}
            </Grid>

            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Semana de estudio jurídico:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              {record.semana_estudio_juridico || `-`}
            </Grid>

            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Comercial asignado:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              <ReferenceField
                label="Comercial"
                source="comercial_asignado_id"
                record={record.caso}
                reference="usuarios"
              >
                <TextField source="name" />
              </ReferenceField>
            </Grid>
            <Grid item xs={xsSizeCol1}>
              <Typography variant="body1" className={classes.labelBoldRight}>
                Abogado asignado:
              </Typography>
            </Grid>
            <Grid item xs={xsSizeCol2}>
              <ReferenceField
                label="Abogado"
                source="abogado_asignado_id"
                record={record.caso}
                reference="usuarios"
              >
                <TextField source="name" />
              </ReferenceField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "15px" }}>
          <Typography variant="body1" className={classes.bold}>
            Asunto
          </Typography>
          <Typography variant="body1">{record.asunto}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const CreateRelatedCommentButton = ({ record }) => (
  <Button
    component={RouterLink}
    to={{
      pathname: "/comentarios/create",
      state: { record: { caso_id: record.id } },
    }}
    color="primary"
    variant="contained"
    size="small"
  >
    <Typography variant="caption">+ Crear comentario</Typography>
  </Button>
);

const ComentariosRelated = ({ createButton = true }) => {
  const classes = useCasosStyles();
  const record = useRecordContext();
  const { data: comentarios, ids: comentariosIds } = useGetList(
    "comentarios",
    { page: 1, perPage: 1000 },
    { field: "created_at", order: "DESC" },
    { caso_id: record && record.id }
  );

  const agruparFirmaLogs = (cIds, cs) => {
    let enGrupo = false;
    const comentariosReagrupados = [];
    for (const cId of cIds) {
      const c = cs[cId];

      if (c.tipo_comentario === "firma_digital_log") {
        if (!enGrupo) {
          enGrupo = true;
          comentariosReagrupados.push([c]);
        } else {
          comentariosReagrupados[comentariosReagrupados.length - 1].push(c);
        }
      } else {
        enGrupo = false;
        comentariosReagrupados.push(c);
      }
    }
    return comentariosReagrupados;
  };

  const registros = useMemo(
    () => agruparFirmaLogs(comentariosIds, comentarios),
    [comentariosIds, comentarios]
  );

  const renderRegistro = (r) => (
    <Step key={`${r.tipo_comentario}-${r.id}`} expanded active completed>
      {r.tipo_comentario !== "firma_digital_log" && (
        <StepLabel
          StepIconComponent={() => {
            return <LogActivityIcon registro={r} />;
          }}
        >
          {new Date(r?.created_at).toLocaleString()}
        </StepLabel>
      )}
      <StepContent>
        <LogActivityComponent
          key={`log_activity_record_${r.id}`}
          registro={r}
        />
      </StepContent>
    </Step>
  );

  const [open, setOpen] = useState([]);

  const setOne = (idx, value) => {
    setOpen((prev) => {
      const newOpen = [...prev];
      newOpen[idx] = value;
      return newOpen;
    });
  };

  const toggleOpen = (idx) => {
    if (!open[idx]) {
      setOne(idx, true);
    } else {
      setOne(idx, !open[idx]);
    }
  };

  return (
    <>
      <Box p={1}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h6">Registro acciones</Typography>
          </Grid>
          <Grid item xs={4}>
            {createButton && <CreateRelatedCommentButton record={record} />}
          </Grid>
        </Grid>
      </Box>
      <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
        {registros &&
          registros.map((r, idx) => {
            if (isArray(r)) {
              // si es array expandimos y normal
              return (
                <Step
                  key={`key-grupo-log-firma-${idx}`}
                  expanded
                  active
                  completed
                >
                  <StepLabel
                    StepIconComponent={() => {
                      return (
                        <FolderIcon
                          fontSize="small"
                          color="primary"
                          style={{ paddingLeft: 3 }}
                        />
                      );
                    }}
                  >
                    {"Eventos de firma digital agrupados"}{" "}
                    <button onClick={() => toggleOpen(idx)}>
                      {isBoolean(open[idx]) && open[idx]
                        ? `Ocultar eventos`
                        : `Ver eventos`}
                    </button>
                  </StepLabel>
                  <StepContent>
                    {isBoolean(open[idx]) && open[idx] && (
                      <Stepper
                        orientation="vertical"
                        classes={{ root: classes.stepper }}
                      >
                        {r.map((rs) => renderRegistro(rs))}
                      </Stepper>
                    )}
                  </StepContent>
                </Step>
              );
            }
            return renderRegistro(r);
          })}
      </Stepper>
    </>
  );
};

const CasosShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label="Back"
      icon={<ChevronLeft />}
      variant="contained"
      color="secondary"
    />
  </TopToolbar>
);

const CasosAccionesEstado = (props) => (
  <TopToolbar>
    <BotonesEstado {...props} />
  </TopToolbar>
);

export const CasosShow = (props) => {
  return (
    <Show {...props} title="Detalle revolving" actions={<CasosShowActions />}>
      <>
        <LeadShowDescription actions={<CasosAccionesEstado />} />
        <TabbedShowLayout>
          <Tab label="Descripción del revolving">
            <CasoShowDescripcion />
            <ComentariosRelated />
          </Tab>
          <Tab label="Documentación">
            <ListadoDocumentosPorCaso />
          </Tab>
        </TabbedShowLayout>
      </>
    </Show>
  );
};

const CasosCreateLeadShowWrapper = ({ precontactoId }) => {
  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "precontactos",
    payload: { id: precontactoId },
  });
  if (!precontactoId) return <></>;
  if (loading || !data) return <CircularProgress />;
  if (error) return <p>Error cargando contacto.</p>;
  return (
    <>
      <Typography variant="h6" gutterBottom>
        LEAD-{data.lead_id}
      </Typography>
      <PrecontactoShowCard title="Precontacto" precontacto={data} />
    </>
  );
};

const CasoCreateActions = (props) => {
  return (
    <TopToolbar>
      <BackButton />
    </TopToolbar>
  );
};

const CasosCreateInner = ({
  history: { goBack },
  location,
  staticContext,
  ...props
}) => {
  const onSuccess = () => goBack();
  return (
    <Create
      {...props}
      title="Crear revolving para este lead"
      // mutationMode="optimistic"
      onSuccess={onSuccess}
      actions={<CasoCreateActions />}
    >
      <SimpleForm>
        {location?.state?.record?.precontacto_id && (
          <CasosCreateLeadShowWrapper
            precontactoId={location.state.record.precontacto_id}
          />
        )}
        <CasoBaseCreate />
      </SimpleForm>
    </Create>
  );
};

export const CasosCreate = withRouter(CasosCreateInner);

const CasosEditAside = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <>
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <LeadShowDescription />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <PrecontactoShowCard
              title="Contacto"
              precontacto={record.precontacto}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <ComentariosRelated createButton={false} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const CasosEditToolbar = (props) => {
  const record = useRecordContext();

  return (
    <Toolbar {...props}>
      <Grid
        container
        spacing={3}
      >
        <Grid item xs>
          <SaveButton />
        </Grid>
        <Grid item xs={9}>
          &nbsp;
        </Grid>
        <Grid item xs>
          <DeleteWithConfirmButton
            confirmContent="Va a borrar el registro. ¿ Esta ústed seguro ?"
            record={record}
          />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const CasosEditInner = ({
  history: { goBack },
  location,
  staticContext,
  ...props
}) => {
  const onSuccess = () => goBack();
  return (
    <Edit
      {...props}
      title="Editar revolving"
      mutationMode="optimistic"
      onSuccess={onSuccess}
      actions={<CasoCreateActions />}
      aside={<CasosEditAside />}
    >
      <SimpleForm 
        // toolbar={<CasosEditToolbar />}
        >
        <CasoBaseEdit />
      </SimpleForm>
    </Edit>
  );
};

export const CasosEdit = withRouter(CasosEditInner);

const TextInputWithExtraProps = ({ extraPropsFn, ...props }) => {
  const record = useRecordContext(props);
  return <TextInput {...props} {...extraPropsFn(record)} />;
};

const CasoBaseEdit = () => {
  return (
    <>
      <Labeled label="ID revolving en la BBDD" resource="casos">
        <TextInput source="id" options={{ label: "" }} disabled />
      </Labeled>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Labeled label="Número de expediente" resource="casos">
        <TextInputWithExtraProps
          source="expediente"
          options={{ label: "" }}
          helperText="Número de expediente"
          extraPropsFn={(r) => ({ disabled: !casoPuedeEditarExpediente(r) })}
        />
      </Labeled>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Labeled label="Comercial asignado" resource="usuarios">
        <ComercialAsignadoInput source="comercial_asignado_id" label="" />
      </Labeled>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Labeled label="Abogado asignado" resource="usuarios">
        <AbogadoAsignadoInput source="abogado_asignado_id" label="" />
      </Labeled>
      <p>Detalles del caso</p>
      <Labeled label="Asunto del revolving" resource="casos" fullWidth>
        <TextInput
          source="asunto"
          options={{ label: "" }}
          helperText="Descripción del revolving"
        />
      </Labeled>
      <Labeled label="Origen del revolving" resource="casos">
        <ReferenceInput
          options={{ label: "" }}
          source="origenlead_id"
          reference="origenesleads"
          validate={[required()]}
        >
          <SelectInput optionText="nombre" />
        </ReferenceInput>
      </Labeled>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Labeled label="Tipo de producto">
        <SelectInput
          options={{ label: "" }}
          source="tipo_producto"
          choices={[
            { id: "tarjeta_credito", name: "Tarjeta de crédito" },
            { id: "linea_credito", name: "Línea de crédito" },
            { id: "prestamo", name: "Préstamo" },
            { id: "microprestamo", name: "Micro-préstamo" },
          ]}
        />
      </Labeled>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Labeled label="Identificación del producto" resource="casos">
        <TextInput
          source="identificacion_producto"
          resource="casos"
          options={{ label: "" }}
        />
      </Labeled>
      <Labeled label="Entidad" resource="casos" fullWidth>
        <ReferenceInput
          options={{ label: "" }}
          source="entidad_id"
          reference="entidades"
          perPage={1000}
        >
          <SelectInput optionText="nombre" />
        </ReferenceInput>
      </Labeled>
      <Labeled label="Forma de contratación" resource="casos">
        <TextInput
          source="forma_contratacion"
          resource="casos"
          options={{ label: "" }}
        />
      </Labeled>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Labeled label="Año contratación" resource="casos">
        <TextInput
          source="anyo_contrato"
          resource="casos"
          options={{ label: "" }}
        />
      </Labeled>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Labeled label="TAE" resource="casos">
        <TextInput source="TAE" resource="casos" options={{ label: "" }} />
      </Labeled>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Labeled label="Semana estudio júridico" resource="casos">
        <TextInput
          source="semana_estudio_juridico"
          resource="casos"
          options={{ label: "" }}
        />
      </Labeled>
    </>
  );
};

import React from "react";
import {
  Card as MuiCard,
  CardContent,
  Box,
  Grid,
  Typography,
  CardHeader,
  IconButton,
  Avatar,
  Collapse,
  styled,
  withStyles,
} from "@material-ui/core";
import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ReferenceInput,
  SelectInput,
  required,
  TextField,
  List,
  Datagrid,
  DateField,
  Show,
  SimpleShowLayout,
  FormWithRedirect,
  Labeled,
  BooleanField,
  BooleanInput,
  Toolbar,
  ReferenceManyField,
  EditButton,
  useGetList,
  ShowButton,
  Button,
  TopToolbar,
  useRecordContext,
  Pagination,
  FilterLiveSearch,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link as RouterLink } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";

import { CasoBaseCreate } from "../casos/casos";
import {
  PrecontactoLeadCreate,
  PrecontactoDetailsShow,
  PrecontactoShowCard,
} from "../precontactos";
import { FilterDateTimeSearch } from "../casos/filters";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
export const LeadCreate = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Create {...props}>
        <SimpleForm>
          <>
            <PrecontactoLeadCreate />
            <CasoBaseCreate />
          </>
        </SimpleForm>
      </Create>
    </div>
  );
};

const CreateRelatedPrecontactoButton = (props) => {
  const record = useRecordContext(props);
  if (!record) return <></>;
  return (
    <Button
      component={RouterLink}
      to={{
        pathname: "/precontactos/create",
        state: { record: { lead_id: record.id } },
      }}
      color="primary"
      variant="contained"
      size="small"
    >
      <Typography style={{ fontSize: "12px" }}>
        + Crear nuevo precontacto para este lead
      </Typography>
    </Button>
  );
};

const LeadShowButtons = (props) => (
  <TopToolbar>
    <CreateRelatedPrecontactoButton />
  </TopToolbar>
);

export const LeadShow = (props) => {
  return (
    <Show
      {...props}
      title="Detalle lead"
      component="div"
      actions={<LeadShowButtons />}
    >
      <LeadForm />
    </Show>
  );
};

const LeadsPagination = (props) => (
  <Pagination
    rowsPerPageOptions={[10, 25, 50, 100, 200, 300, 400, 500]}
    {...props}
  />
);

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "25em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const FilterSidebar = (props) => {
  return (
    <Card>
      <CardHeader
        title="Filtros"
        action={
          <IconButton
            aria-label="limpiar filtros"
            onClick={() => {
              props.setFilters({ filter: null, order: null, sort: null });
            }}
          >
            <ClearIcon />
          </IconButton>
        }
      />
      <CardContent>
        <FilterLiveSearch source="q" label="Buscar..." />
        <FilterLiveSearch source="id" label="Buscar lead id..." />
      </CardContent>
    </Card>
  );
};

export const LeadList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      title="Lista de leads"
      aside={<FilterSidebar />}
      pagination={<LeadsPagination />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" label="Lead ID" />
        <TextField source="precontacto.email" label="E-Mail" />
        <TextField source="precontacto.telefono" label="Teléfono" />
        <TextField source="precontacto.nombre_alias" label="Alias" />
        <TextField
          source="precontacto.nombre_completo"
          label="Nombre Completo"
        />

        <DateField source="published_at" label="" />
      </Datagrid>
    </List>
  );
};

export const CreateRelatedCasoButton = ({ record }) => (
  <Button
    component={RouterLink}
    to={{
      pathname: "/casos/create",
      state: { record: { precontacto_id: record?.id } },
    }}
    color="primary"
    variant="contained"
    size="small"
  >
    <Typography style={{ fontSize: "12px" }}>
      + Crear nuevo revolving para este contacto
    </Typography>
  </Button>
);

const useCasosDescriptionStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CasosDescriptionList = ({ precontactoRecord }) => {
  const classes = useCasosDescriptionStyles();

  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card style={{ marginTop: "1em" }}>
      <CardHeader
        avatar={
          <Avatar
            variant="square"
            style={{ width: 100, backgroundColor: "gray", color: "white" }}
            aria-label="Precontacto"
          >
            P-{precontactoRecord.id}
          </Avatar>
        }
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title={precontactoRecord.nombre_completo}
        subheader={`Creado ${new Date(
          precontactoRecord.created_at
        ).toLocaleString()}`}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <CreateRelatedCasoButton record={precontactoRecord} />
            </Grid>
            <Grid item xs={3}>
              <PrecontactoShowCard
                precontacto={precontactoRecord}
                createRelatedCasoButtonHide={true}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6">
                Listado de revolvings del contacto
              </Typography>
              <ReferenceManyField
                reference="casos"
                target="precontacto_id"
                label="Revolvings"
                record={precontactoRecord}
              >
                <Datagrid
                  empty={<p>No hay revolvings para este contacto.</p>}
                  resource="casos"
                  rowClick="show"
                >
                  <DateField source="created_at" label="Entrada" />
                  <TextField source="origenlead.nombre" label="Origen" />
                  <TextField source="asunto" label="Asunto" />
                  <TextField source="intentos" label="Intentos" />
                  <ShowButton />
                </Datagrid>
              </ReferenceManyField>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const PrecontactosDescriptionList = ({ record: leadRecord }) => {
  const { data: precontactos, ids: precontactosIds } = useGetList(
    "precontactos",
    { page: 1, perPage: 1000 },
    { field: "created_at", order: "DESC" },
    { lead_id: leadRecord && leadRecord.id }
  );

  if (!precontactosIds) return null;
  if (precontactosIds.length === 0) return <p>No hay contactos</p>;

  return (
    <div>
      {precontactosIds &&
        precontactosIds.map((id) => (
          <CasosDescriptionList
            key={`caso_${leadRecord.id}_${id}`}
            precontactoRecord={precontactos[id]}
          />
        ))}
    </div>
  );
};

const LeadForm = (props) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Box>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    LEAD-{formProps.record.id}
                  </Typography>
                  <PrecontactoDetailsShow
                    record={formProps.record.precontacto}
                  />
                </Grid>
              </Grid>
            </CardContent>
            {/* <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={false}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="lead"
            /> */}
          </Card>
          <PrecontactosDescriptionList record={formProps.record} />
        </Box>
      )}
    />
  );
};
